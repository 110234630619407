/* eslint-disable no-param-reassign */
import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, STYLE_RULE_NAMES } from '../constants';
import renderEditorNode from '../development/editorNode';
import {
  AddBlockActionsProps,
  Block,
  BlockHelpers,
  BlockRenderPackage,
} from '../types';

const CONTAINER_BLOCK_STYLE_ELEMENTS = {
  ...BLOCK_STYLE_ELEMENT,
} as const;

const defaultStyles = {
  [CONTAINER_BLOCK_STYLE_ELEMENTS.BLOCK]: {
    mobile: {
      [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
      [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.LAYOUT_DIRECTION]: 'column',
      [STYLE_RULE_NAMES.LAYOUT_SPACING]: 'flex-start',
      [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.PADDING]: '12px 12px 12px 12px',
      // private
      [STYLE_RULE_NAMES.DISPLAY]: 'flex',
      [STYLE_RULE_NAMES.FLEX]: '1',
      [STYLE_RULE_NAMES.POSITION]: 'relative',
    },
  },
};

const addBlockActions = ({
  block,
  blockActions,
  popupActions,
  undeletableIds,
}: AddBlockActionsProps) => {
  if (!undeletableIds?.includes(block.id))
    blockActions.deleteBlock = () => popupActions.deleteBlock?.(block.id);
};

function isEmpty({ children = [] }: Block) {
  return children.length === 0;
}

function render(renderData: BlockRenderPackage) {
  const { children, blockActions, classes, environment } = renderData;
  const { block: blockClasses, editorNode: editorNodeClasses } = classes;

  return html`
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(environment.isDevelopment ? 'true' : undefined)}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${environment.isDevelopment
        ? renderEditorNode(editorNodeClasses, blockActions.deleteBlock)
        : nothing}
      ${children}
    </div>
  `;
}

const containerBlockHelpers: BlockHelpers = {
  addBlockActions,
  defaultStyles,
  isEmpty,
  render,
  styleElements: CONTAINER_BLOCK_STYLE_ELEMENTS,
};

export default containerBlockHelpers;
