import { BLOCK_TYPES } from '../constants';
import buttonBlockHelpers from './buttonBlock';
import closeButtonBlockHelpers from './closeButtonBlock';
import containerBlockHelpers from './containerBlock';
import emailInputBlockHelpers from './emailInputBlock';
import headingBlockHelpers from './headingBlock';
import imageBlockHelpers from './imageBlock';
import otpInputBlockHelpers from './otpInputBlock';
import phoneInputBlockHelpers from './phoneInput';
import rootBlockHelpers from './rootBlock';
import rootTempBlockHelpers from './rootTempBlock';
import spinToWinBlockHelpers from './spinToWinBlock';
import teaserBlockHelpers from './teaserBlock';
import teaserRootBlockHelpers from './teaserRootBlock';
import textBlockHelpers from './textBlock';
const blockUtilsMap = {
    [BLOCK_TYPES.BUTTON]: buttonBlockHelpers,
    [BLOCK_TYPES.CLOSE_BUTTON]: closeButtonBlockHelpers,
    [BLOCK_TYPES.CONTAINER]: containerBlockHelpers,
    [BLOCK_TYPES.EMAIL_INPUT]: emailInputBlockHelpers,
    [BLOCK_TYPES.HEADING]: headingBlockHelpers,
    [BLOCK_TYPES.IMAGE]: imageBlockHelpers,
    [BLOCK_TYPES.OTP_INPUT]: otpInputBlockHelpers,
    [BLOCK_TYPES.PHONE_INPUT]: phoneInputBlockHelpers,
    [BLOCK_TYPES.ROOT]: rootBlockHelpers,
    [BLOCK_TYPES.ROOT_TEMP]: rootTempBlockHelpers,
    [BLOCK_TYPES.TEASER]: teaserBlockHelpers,
    [BLOCK_TYPES.TEASER_ROOT]: teaserRootBlockHelpers,
    [BLOCK_TYPES.TEXT]: textBlockHelpers,
    [BLOCK_TYPES.SPIN_TO_WIN]: spinToWinBlockHelpers,
};
const SDKBlockHelpers = {
    render(renderData) {
        const blockHelpers = blockUtilsMap[renderData.block.type];
        return blockHelpers.render(renderData);
    },
    getTextElements(blockType) {
        var _a;
        const blockHelpers = blockUtilsMap[blockType];
        return (_a = blockHelpers.textElements) !== null && _a !== void 0 ? _a : {};
    },
    getStyleElements(blockType) {
        var _a;
        const blockHelpers = blockUtilsMap[blockType];
        return (_a = blockHelpers.styleElements) !== null && _a !== void 0 ? _a : {};
    },
    getDefaultStyles(blockType) {
        const blockHelpers = blockUtilsMap[blockType];
        return blockHelpers.defaultStyles;
    },
    isEmpty(block) {
        var _a;
        const { type } = block;
        const blockHelpers = blockUtilsMap[type];
        return !!((_a = blockHelpers.isEmpty) === null || _a === void 0 ? void 0 : _a.call(blockHelpers, block));
    },
    addBlockActions({ block, blockActions, environment, popupActions, stepBlocks, undeletableIds, }) {
        var _a;
        const { type } = block;
        const blockHelpers = blockUtilsMap[type];
        (_a = blockHelpers.addBlockActions) === null || _a === void 0 ? void 0 : _a.call(blockHelpers, {
            block,
            blockActions,
            environment,
            popupActions,
            stepBlocks,
            undeletableIds,
        });
    },
    validateBlockData(block, blockData) {
        var _a;
        const { type } = block;
        const blockHelpers = blockUtilsMap[type];
        return ((_a = blockHelpers.validateBlockData) === null || _a === void 0 ? void 0 : _a.call(blockHelpers, block, blockData)) || null;
    },
};
export default SDKBlockHelpers;
