import intlTelInput from 'intl-tel-input';
import { LitElement } from 'lit';
import { HtmlSafeId } from '../types/render';

class PhoneInputWrapper extends LitElement {
  declare inputId: HtmlSafeId;

  declare countryCode?: string;

  declare phoneInputInstance: intlTelInput.Plugin | null;

  declare inputMargin?: string;

  static get properties() {
    return {
      inputId: { type: String },
      countryCode: { type: String },
      phoneInputInstance: { type: Object, state: true },
      inputMargin: { type: String },
    };
  }

  protected createRenderRoot() {
    return this;
  }

  getFormattedInputValue(): string | undefined {
    return this.phoneInputInstance?.getNumber();
  }

  updatePhoneInput() {
    // This will sometimes run while the input is in the DOM but not yet rendered (has a width of 0)
    // We need the input to be fully rendered so iti can calculate padding correctly so the setTimeout solves for that
    setTimeout(() => {
      const input = this.renderRoot.querySelector(`.${this.inputId}`);

      if (!input) return null;

      this.phoneInputInstance = intlTelInput(input, {
        autoPlaceholder: 'off',
        dropdownContainer: document.body,
        initialCountry: this.countryCode ?? 'US',
        preferredCountries: ['us', 'ca'],
        separateDialCode: true,
        utilsScript:
          'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js',
      });
    }, 0);
  }

  /*
  intl-tel-input modifies the dom after it initializes and needs to apply some inline styles
  to the phone input to get the country dropdown sized appropriately. Notably it sets a margin-bottom: 0 !important
  which conflicts with user specified styles. The two functions below will copy the styles from the input to the
  render root to ensure that the styles are applied as specified by the user.
  */
  copyInstanceStylesToRenderRoot() {
    if (this.inputMargin) {
      (this.renderRoot as HTMLElement).style.margin = this.inputMargin;
    }
  }

  copyInputStylesToRenderRoot() {
    const input = this.renderRoot.querySelector(`.${this.inputId}`);
    if (input) {
      (this.renderRoot as HTMLElement).style.margin =
        getComputedStyle(input).margin;
    }
  }

  getValidationError(): string | null {
    if (this.phoneInputInstance?.isValidNumber()) return null;

    const validationError = this.phoneInputInstance?.getValidationError();

    switch (validationError) {
      case intlTelInputUtils.validationError.TOO_SHORT:
        return 'Phone number is too short';
      case intlTelInputUtils.validationError.TOO_LONG:
        return 'Phone number is too long';
      default:
        return 'Invalid phone number';
    }
  }

  protected firstUpdated(
    _changedProperties: Map<string | number | symbol, unknown>,
  ): void {
    super.firstUpdated(_changedProperties);
    this.updatePhoneInput();
  }

  // The dropdown position breaks if the popup becomes scrollable so we need to force close it on scroll
  // See https://github.com/jackocnr/intl-tel-input?tab=readme-ov-file#troubleshooting for more details
  static closeDropdownOnScroll(): void {
    window.dispatchEvent(
      new Event('scroll', {
        cancelable: true,
        bubbles: true,
      }),
    );
  }

  // The any is not ideal here but is needed to fix conflicts with differing SDK and FE versions of lit
  protected updated(_changedProperties: any): void {
    super.updated(_changedProperties);

    this.copyInstanceStylesToRenderRoot();
  }

  connectedCallback(): void {
    super.connectedCallback();

    this.copyInputStylesToRenderRoot();

    document
      .querySelector('form')
      ?.addEventListener('scroll', PhoneInputWrapper.closeDropdownOnScroll);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.phoneInputInstance?.destroy();
    document
      .querySelector('form')
      ?.removeEventListener('scroll', PhoneInputWrapper.closeDropdownOnScroll);
  }
}

if (!customElements.get('ps-phone-input-block-iti-wrapper')) {
  customElements.define('ps-phone-input-block-iti-wrapper', PhoneInputWrapper);
}
