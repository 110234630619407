import { html, LitElement, nothing } from 'lit';
import updatePhoneInput from '../../desktop/components/updatePhoneInput';
import { PopupPageQuestion } from '../../types/popup';
import { CUSTOM_CSS_SELECTOR_PREFIX } from '../constants';
import { defineElementSafely } from '../utils';
import './phoneInput.css';

const PHONE_INPUT_ID = `${CUSTOM_CSS_SELECTOR_PREFIX}phone-field-input`;

class PhoneInput extends LitElement {
  declare ariaDescribedBy: string;
  declare countryCode: string;
  declare phoneInputInstance: intlTelInput.Plugin | null;
  declare question: PopupPageQuestion;
  declare hasValue: boolean;
  declare isDropdownOpen: boolean;

  static get properties() {
    return {
      ariaDescribedBy: { type: String },
      question: { type: Object },
      countryCode: { type: String },
      phoneInputInstance: { state: true },
      hasValue: { state: true },
      isDropdownOpen: { state: true },
    };
  }

  constructor() {
    super();
    this.hasValue = false;
    this.isDropdownOpen = false;
    this.onFirstUpdate = this.onFirstUpdate.bind(this);
  }

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  getFormattedInputValue(): { phone: string; country?: string } {
    const { iso2 } = this.phoneInputInstance?.getSelectedCountryData() ?? {};

    return {
      phone: this.phoneInputInstance?.getNumber() as string,
      country: iso2,
    };
  }

  onFirstUpdate(): void {
    const phoneInputSelector = `#${PHONE_INPUT_ID} input`;
    this.phoneInputInstance = updatePhoneInput(
      this.countryCode,
      phoneInputSelector,
      {
        dropdownContainer: document.body,
      },
    );

    const inputElement = document.querySelector(phoneInputSelector);

    inputElement?.addEventListener('blur', (event: Event) => {
      const inputValue = ((event as FocusEvent).target as HTMLInputElement)
        ?.value;
      this.hasValue = !!inputValue?.trim();
    });
    inputElement?.addEventListener('open:countrydropdown', () => {
      this.isDropdownOpen = true;
    });
    inputElement?.addEventListener('close:countrydropdown', () => {
      this.isDropdownOpen = false;
    });
  }

  static closeDropdownOnScroll(): void {
    window.dispatchEvent(
      new Event('scroll', {
        cancelable: true,
        bubbles: true,
      }),
    );
  }

  connectedCallback(): void {
    super.connectedCallback();
    document
      .querySelector('ps-popup-form')
      ?.addEventListener('scroll', PhoneInput.closeDropdownOnScroll);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.phoneInputInstance?.destroy();
    document
      .querySelector('ps-popup-form')
      ?.removeEventListener('scroll', PhoneInput.closeDropdownOnScroll);
  }

  render() {
    return html`
      <ps-input
        type="tel"
        .question="${this.question}"
        id="${PHONE_INPUT_ID}"
        .onFirstUpdate="${this.onFirstUpdate}"
        ariaDescribedBy="${this.ariaDescribedBy ?? nothing}"
        data-filled="${this.hasValue || this.isDropdownOpen}"
      ></ps-input>
    `;
  }
}

defineElementSafely('ps-phone-input', PhoneInput);
