import { hideIframe } from './iframe';
import {
  BIS_MOBILE_POPUP_CONTAINER_ID,
  POPUP_WIDGET_CONTAINER_SELECTOR,
  BIS_ROOT_ID,
} from './constants';
import { configStateStore } from '../widget/common/helpers/stateManager';

const FADE_OUT_MS = 500;
const DEFAULT_ELEMENT = 'ps-desktop-widget__container';
export const FADE_IN_CLASS = 'fade-in';
export const FADE_OUT_CLASS = 'fade-out';

export const focusableElementsSelector =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';

export const fadeOut = (
  elementId = DEFAULT_ELEMENT,
  callback = hideIframe,
  fadeInClass = FADE_IN_CLASS,
  fadeOutClass = FADE_OUT_CLASS,
) => {
  const rootElement = document.getElementById(elementId);

  if (rootElement) {
    rootElement.classList.remove(fadeInClass);
    rootElement.classList.add(fadeOutClass);
  }

  return setTimeout(() => {
    if (rootElement && rootElement.classList) {
      rootElement.classList.remove(fadeOutClass);
      rootElement.classList.add(fadeInClass);
    }
    callback();
  }, FADE_OUT_MS);
};

/**
 * Simulates button keyboard behavior for clickable divs. This is a band-aid for
 * existing, inaccessible markup. New markup should use a button tag instead.
 * @param {KeyboardEvent} event
 */
export const handleKeyDownForClickableDivs = (event) => {
  if (event.key === 'Enter' || event.key === ' ') {
    event.preventDefault();
    event.target.click();
  }
};

export const filterVisible = (elements) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    if (window.getComputedStyle(element).visibility !== 'hidden') {
      return element;
    }
  }

  return null;
};

export const focusFirstPopUpInput = (popup) => {
  const configManager = configStateStore.getState();
  if (configManager.isPreview) return;

  const firstFocusableElement =
    filterVisible(popup.querySelectorAll('input')) ??
    filterVisible(popup.querySelectorAll('button, [role="button"]')) ??
    filterVisible(popup.querySelectorAll('a'));

  firstFocusableElement?.focus();
};

export const delayUntilPopupOpen = (onOpen) => {
  document.querySelector(`.${FADE_IN_CLASS}`)?.addEventListener(
    'animationend',
    () => {
      onOpen();
    },
    {
      once: true,
    },
  );
};

export const handlePopupKeyDown = (onExit, tabContainerId) => (event) => {
  if (event.key === 'Escape') {
    onExit();
  }

  if (event.key === 'Tab') {
    const focusableElements = tabContainerId
      ? document
          .getElementById(tabContainerId)
          .querySelectorAll(focusableElementsSelector)
      : document.querySelectorAll(focusableElementsSelector);
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement =
      focusableElements[focusableElements.length - 1];

    if (event.shiftKey && document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus();
      event.preventDefault();
    } else if (
      !event.shiftKey &&
      document.activeElement === lastFocusableElement
    ) {
      firstFocusableElement.focus();
      event.preventDefault();
    }
  }
};

/**
 * VoiceOver on iOS does not respect the role="dialog" and aria-modal="true" attributes.
 * A user is able to tab into elements behind the popup. The work around is to set
 * aria-hidden="true" on all siblings of the popup so the screen reader doesn't read them.
 */
export const hideElementsBehindPopupFromScreenReader = (
  id = POPUP_WIDGET_CONTAINER_SELECTOR,
) => {
  const children = document.querySelectorAll(`body > *:not(#${id})`);
  children.forEach((node) => {
    if (node.getAttribute('aria-hidden')) {
      node.setAttribute('data-keep-hidden', node.getAttribute('aria-hidden'));
    }

    node.setAttribute('aria-hidden', 'true');
  });
};

export const unhideElementsBehindPopupFromScreenReader = (
  id = POPUP_WIDGET_CONTAINER_SELECTOR,
) => {
  const children = document.querySelectorAll(`body > *:not(#${id})`);
  children.forEach((node) => {
    if (node.getAttribute('data-keep-hidden')) {
      node.setAttribute('aria-hidden', node.getAttribute('data-keep-hidden'));
      node.removeAttribute('data-keep-hidden');
    } else {
      node.removeAttribute('aria-hidden');
    }
  });
};

export const trapFocusInElement = (
  containerId = BIS_MOBILE_POPUP_CONTAINER_ID,
  popupId = BIS_ROOT_ID,
) => {
  document.getElementById(containerId)?.removeAttribute('aria-hidden');
  hideElementsBehindPopupFromScreenReader(popupId);
};

export const removeElementFocusTrap = (
  containerId = BIS_MOBILE_POPUP_CONTAINER_ID,
  popupId = BIS_ROOT_ID,
) => {
  document.getElementById(containerId)?.setAttribute('aria-hidden', 'true');
  unhideElementsBehindPopupFromScreenReader(popupId);
};
