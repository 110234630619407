/* TODO(Kameron Ahler): These could probably be refactored and removed with a
little more effort. They are just holdovers from an early way of "determining"
layout direction when we iterated through styles in the sidebar. It's
essentially a map for a "custom" style rule name to a real css declaration. */

export const layoutDirection = (layoutDirection: string) => {
  return `
    flex-direction: ${layoutDirection};
  `;
};

export const layoutSpacing = (layoutSpacing: string) => {
  return `
    justify-content: ${layoutSpacing};
  `;
};

export const CustomStyleRulesToHandlers: Record<string, any> = {
  layoutDirection,
  layoutSpacing,
};
