/* eslint-disable no-param-reassign */
import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import {
  BLOCK_STYLE_ELEMENT,
  BUTTON_STYLE_ELEMENT,
  DEFAULT_COLORS,
  STYLE_RULE_NAMES,
  SVG_STYLE_ELEMENT,
} from '../constants';
import {
  AddBlockActionsProps,
  BlockHelpers,
  BlockRenderPackage,
} from '../types';

const TEASER_ROOT_BLOCK_STYLE_ELEMENTS = {
  ...BLOCK_STYLE_ELEMENT,
  ...BUTTON_STYLE_ELEMENT,
  ...SVG_STYLE_ELEMENT,
} as const;

const defaultStyles = {
  [TEASER_ROOT_BLOCK_STYLE_ELEMENTS.BLOCK]: {
    mobile: {
      // private
      [STYLE_RULE_NAMES.INSET]: 'auto auto 16px 16px',
      [STYLE_RULE_NAMES.POSITION]: 'absolute',
    },
  },
  [TEASER_ROOT_BLOCK_STYLE_ELEMENTS.BUTTON]: {
    mobile: {
      // private
      [STYLE_RULE_NAMES.ALIGN_ITEMS]: 'center',
      [STYLE_RULE_NAMES.BACKGROUND_COLOR]:
        DEFAULT_COLORS.TEASER_CLOSE_BUTTON_BG,
      [STYLE_RULE_NAMES.BORDER_COLOR]: 'unset',
      [STYLE_RULE_NAMES.BORDER_RADIUS]: '99em',
      [STYLE_RULE_NAMES.BORDER_STYLE]: 'unset',
      [STYLE_RULE_NAMES.BORDER_WIDTH]: 'unset',
      [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
      [STYLE_RULE_NAMES.DISPLAY]: 'flex',
      [STYLE_RULE_NAMES.HEIGHT]: '24px',
      [STYLE_RULE_NAMES.INSET]: '-12px auto auto -12px',
      [STYLE_RULE_NAMES.JUSTIFY_CONTENT]: 'center',
      [STYLE_RULE_NAMES.LAYOUT_SPACING]: 'center',
      [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.POSITION]: 'absolute',
      [STYLE_RULE_NAMES.WIDTH]: '24px',
    },
  },
  [TEASER_ROOT_BLOCK_STYLE_ELEMENTS.SVG]: {
    mobile: {
      // private
      [STYLE_RULE_NAMES.HEIGHT]: '16px',
      [STYLE_RULE_NAMES.WIDTH]: '16px',
    },
  },
};

const addBlockActions = ({
  block,
  blockActions,
  popupActions,
  undeletableIds,
}: AddBlockActionsProps) => {
  blockActions.onClick = () => popupActions.hardClose();

  if (!undeletableIds?.includes(block.id))
    blockActions.deleteBlock = () => popupActions.deleteBlock?.(block.id);
};

function render(renderData: BlockRenderPackage) {
  const { blockActions, classes } = renderData;

  const { block: blockClasses, button: buttonClasses } = classes;

  return html`
    <div class=${classMap(blockClasses)}>
      ${renderData.children}
      <button
        aria-label="Dismiss marketing prompt"
        class=${classMap(buttonClasses)}
        @click=${ifDefined(blockActions.onClick)}
      >
        <svg
          aria-hidden="true"
          fill="currentColor"
          height="16"
          viewBox="0 0 18 18"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m14.53 4.53-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
      </button>
    </div>
  `;
}

const teaserRootBlockHelpers: BlockHelpers = {
  addBlockActions,
  defaultStyles,
  render,
  styleElements: TEASER_ROOT_BLOCK_STYLE_ELEMENTS,
};

export default teaserRootBlockHelpers;
