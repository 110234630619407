import { captureException } from '@sentry/browser';
import { API_GATEWAY_BASE_URL } from '../../../../../helpers/utility';
import { camelCaseKeysDeep } from '../../../../../services/popup/helper';
import { BlockPopup, CustomFont } from '../../third-party-dep/types';
import { PopupEventTypes } from '../../types';

const ANALYTICS_SERVICE_URL = process.env.POPUP_ANALYTICS_SERVICE_URL;

export const getBlockPopups = async (shopId: number): Promise<BlockPopup[]> => {
  try {
    const response = await fetch(
      `${API_GATEWAY_BASE_URL}/v2/public/block_popups/${shopId}`,
    );
    if (!response.ok) {
      throw new Error(`Fetch failed with status code ${response.status}`);
    }

    const data = await response.json();
    return data.popups.map((p: BlockPopup) => camelCaseKeysDeep(p));
  } catch (error) {
    captureException(
      new TypeError('Failed to load block popups', { cause: error }),
    );

    return [];
  }
};

export const getCustomFonts = async (shopId: number): Promise<CustomFont[]> => {
  try {
    const response = await fetch(
      `${API_GATEWAY_BASE_URL}/v2/public/block_popups/custom_fonts/${shopId}`,
    );
    if (!response.ok) {
      throw new Error(`Fetch failed with status code ${response.status}`);
    }

    const data = await response.json();

    return data.custom_fonts.map((p: CustomFont) => camelCaseKeysDeep(p));
  } catch (error) {
    captureException(
      new TypeError('Failed to load custom fonts', { cause: error }),
    );

    return [];
  }
};

export const postPopupEvent = (
  eventType: PopupEventTypes,
  shopId: number,
  popupId: string,
  country: string | null,
  platform: 'MOBILE' | 'DESKTOP',
) => {
  const url = `${ANALYTICS_SERVICE_URL}/api/v1/events`;
  const data = {
    country: country?.toUpperCase(),
    event: eventType,
    platform,
    popup_id: popupId,
    shop_id: shopId,
  };

  // Note for debugging, requests sent with sendBeacon do not appear in the fetch/xhr section of network tab
  // They're shown as separate requests with a type of "ping"
  navigator.sendBeacon(url, JSON.stringify(data));
};
