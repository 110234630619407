import { BLOCK_TYPES } from '../constants';
import {
  AddBlockActionsProps,
  Block,
  BlockHelpers,
  BlockRenderPackage,
  BlockType,
} from '../types';
import buttonBlockHelpers from './buttonBlock';
import closeButtonBlockHelpers from './closeButtonBlock';
import containerBlockHelpers from './containerBlock';
import emailInputBlockHelpers from './emailInputBlock';
import headingBlockHelpers from './headingBlock';
import imageBlockHelpers from './imageBlock';
import otpInputBlockHelpers from './otpInputBlock';
import phoneInputBlockHelpers from './phoneInput';
import rootBlockHelpers from './rootBlock';
import teaserBlockHelpers from './teaserBlock';
import teaserRootBlockHelpers from './teaserRootBlock';
import textBlockHelpers from './textBlock';

const blockUtilsMap: Record<BlockType, BlockHelpers> = {
  [BLOCK_TYPES.BUTTON]: buttonBlockHelpers,
  [BLOCK_TYPES.CLOSE_BUTTON]: closeButtonBlockHelpers,
  [BLOCK_TYPES.CONTAINER]: containerBlockHelpers,
  [BLOCK_TYPES.EMAIL_INPUT]: emailInputBlockHelpers,
  [BLOCK_TYPES.HEADING]: headingBlockHelpers,
  [BLOCK_TYPES.IMAGE]: imageBlockHelpers,
  [BLOCK_TYPES.OTP_INPUT]: otpInputBlockHelpers,
  [BLOCK_TYPES.PHONE_INPUT]: phoneInputBlockHelpers,
  [BLOCK_TYPES.ROOT]: rootBlockHelpers,
  [BLOCK_TYPES.TEASER]: teaserBlockHelpers,
  [BLOCK_TYPES.TEASER_ROOT]: teaserRootBlockHelpers,
  [BLOCK_TYPES.TEXT]: textBlockHelpers,
};

const SDKBlockHelpers = {
  render(renderData: BlockRenderPackage) {
    const blockHelpers = blockUtilsMap[renderData.block.type];
    return blockHelpers.render(renderData);
  },

  getTextElements(blockType: BlockType) {
    const blockHelpers = blockUtilsMap[blockType];
    return blockHelpers.textElements ?? {};
  },

  getStyleElements(blockType: BlockType) {
    const blockHelpers = blockUtilsMap[blockType];
    return blockHelpers.styleElements ?? {};
  },

  getDefaultStyles(blockType: BlockType) {
    const blockHelpers = blockUtilsMap[blockType];
    return blockHelpers.defaultStyles;
  },

  isEmpty(block: Block) {
    const { type } = block;
    const blockHelpers = blockUtilsMap[type];
    return !!blockHelpers.isEmpty?.(block);
  },

  addBlockActions({
    block,
    blockActions,
    environment,
    popupActions,
    stepBlocks,
    undeletableIds,
  }: AddBlockActionsProps) {
    const { type } = block;
    const blockHelpers = blockUtilsMap[type];
    blockHelpers.addBlockActions?.({
      block,
      blockActions,
      environment,
      popupActions,
      stepBlocks,
      undeletableIds,
    });
  },

  validateBlockData(block: Block, blockData: any) {
    const { type } = block;
    const blockHelpers = blockUtilsMap[type];
    return blockHelpers.validateBlockData?.(block, blockData) || null;
  },
};

export default SDKBlockHelpers;
