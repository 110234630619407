import { ROOT_GRID_AREAS, ROOT_GRID_COLUMNS, ROOT_LAYOUTS } from '../constants';
import { RootGridArea, RootLayout } from '../types/styles';

/* TODO(Kameron Ahler): The layoutDirection and layoutSpacing functions in here
could probably be refactored and removed with a little more effort. They used to
have more complicated side effects, but they now simply point to a static CSS
property. Changing these would have an impact on existing popups though. */
const layoutDirection = (layoutDirection: string) => {
  return `
    flex-direction: ${layoutDirection};
  `;
};
const layoutSpacing = (layoutSpacing: string) => {
  return `
    justify-content: ${layoutSpacing};
  `;
};

const getFullRow = (rowType: RootGridArea) => {
  return rowType === ROOT_GRID_AREAS.DECORATIVE
    ? `"${`${ROOT_GRID_AREAS.DECORATIVE} `.repeat(ROOT_GRID_COLUMNS)}"`
    : `"${`${ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER} `.repeat(
        ROOT_GRID_COLUMNS,
      )}"`;
};

/**
 * Creates a grid-template-areas row that is divided into decorative and main
 * popup container areas. Assumes 6 columns for both areas for now, but will
 * likely need to be able to handle custom column counts in the future.
 */
const getSplitRow = (
  rootLayout: Extract<RootLayout, 'LEFT_DECORATIVE' | 'RIGHT_DECORATIVE'>,
) => {
  const half = ROOT_GRID_COLUMNS / 2;
  return rootLayout === ROOT_LAYOUTS.LEFT_DECORATIVE
    ? // prettier-ignore
      `"${`${ROOT_GRID_AREAS.DECORATIVE} `.repeat(half)} ${`${ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER} `.repeat(half)}"`.trim()
    : // prettier-ignore
      `"${`${ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER} `.repeat(half)} ${`${ROOT_GRID_AREAS.DECORATIVE} `.repeat(half)}"`.trim();
};

const rootLayout = (rootLayout: RootLayout) => {
  if (rootLayout === ROOT_LAYOUTS.NONE)
    // prettier-ignore
    return `
      grid-template-areas: ${getFullRow(ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER)};
      grid-template-rows: auto;
    `.trim();

  if (rootLayout === ROOT_LAYOUTS.TOP_DECORATIVE)
    // prettier-ignore
    return `
      grid-template-areas: ${getFullRow(ROOT_GRID_AREAS.DECORATIVE)} ${getFullRow(ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER)};
      grid-template-rows: auto 1fr;
    `.trim();

  if (rootLayout === ROOT_LAYOUTS.BOTTOM_DECORATIVE)
    // prettier-ignore
    return `
      grid-template-areas: ${getFullRow(ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER)} ${getFullRow(ROOT_GRID_AREAS.DECORATIVE)};
      grid-template-rows: 1fr auto;
    `.trim();

  if (rootLayout === ROOT_LAYOUTS.LEFT_DECORATIVE)
    return `
      grid-template-areas: ${getSplitRow(ROOT_LAYOUTS.LEFT_DECORATIVE)};
      grid-template-rows: auto;
    `.trim();

  return `
    grid-template-areas: ${getSplitRow(ROOT_LAYOUTS.RIGHT_DECORATIVE)};
    grid-template-rows: auto;
  `.trim();
};

export const CustomStyleRulesToHandlers: Record<string, any> = {
  layoutDirection,
  layoutSpacing,
  rootLayout,
};
