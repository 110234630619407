/* eslint-disable no-param-reassign */
import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import {
  BLOCK_STYLE_ELEMENT,
  BUTTON_STYLE_ELEMENT,
  BUTTON_TEXT_ELEMENT,
  CONFIG_ONCLICK_ACTIONS,
  DEFAULT_COLORS,
  STYLE_RULE_NAMES,
  SYSTEM_FONT_FAMILY_STYLE,
} from '../constants';
import renderEditorNode from '../development/editorNode';
import {
  AddBlockActionsProps,
  BlockHelpers,
  BlockRenderPackage,
} from '../types';

export type ButtonBlockTextElements = typeof BUTTON_BLOCK_TEXT_ELEMENTS;

const BUTTON_BLOCK_TEXT_ELEMENTS = {
  ...BUTTON_TEXT_ELEMENT,
} as const;

const BUTTON_BLOCK_STYLE_ELEMENTS = {
  ...BUTTON_STYLE_ELEMENT,
  ...BLOCK_STYLE_ELEMENT,
} as const;

const defaultStyles = {
  [BUTTON_BLOCK_STYLE_ELEMENTS.BLOCK]: {
    mobile: {
      [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.OPACITY]: '1',
      [STYLE_RULE_NAMES.WIDTH]: '100%',
      // private
      [STYLE_RULE_NAMES.POSITION]: 'relative',
    },
  },
  [BUTTON_BLOCK_STYLE_ELEMENTS.BUTTON]: {
    mobile: {
      // default variant uses the following styles
      [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.PRIMARY_BUTTON_BG,
      [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
      [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
      [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
      [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.PRIMARY_BUTTON_TEXT,
      [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
      [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
      [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
      [STYLE_RULE_NAMES.FONT_WEIGHT]: 'bold',
      [STYLE_RULE_NAMES.PADDING]: '8px 8px 8px 8px',
      [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
      [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
      // private
      [STYLE_RULE_NAMES.WIDTH]: '100%',
    },
  },
};

const addBlockActions = ({
  block,
  blockActions,
  popupActions,
  undeletableIds,
}: AddBlockActionsProps) => {
  blockActions.onClick = () => popupActions.handleButtonClick(block);

  if (!undeletableIds?.includes(block.id))
    blockActions.deleteBlock = () => popupActions.deleteBlock?.(block.id);
};

function render(renderData: BlockRenderPackage) {
  const { block, blockActions, content, classes, environment } = renderData;
  const {
    block: blockClasses,
    button: buttonClasses,
    editorNode: editorNodeClasses,
  } = classes;
  const { onClick } = block.config ?? {};
  const buttonContent = content[BUTTON_BLOCK_TEXT_ELEMENTS.BUTTON] ?? 'Button';

  const hasSubmitOnClickAction =
    onClick?.action === CONFIG_ONCLICK_ACTIONS.SUBMIT_AND_ROUTE_TO_STEP;

  return html`
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(environment.isDevelopment ? 'true' : undefined)}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${environment.isDevelopment && blockActions.deleteBlock
        ? renderEditorNode(editorNodeClasses, blockActions.deleteBlock)
        : nothing}
      <button
        class=${classMap(buttonClasses)}
        data-popup-engagement=${onClick?.action === CONFIG_ONCLICK_ACTIONS.CLOSE
          ? nothing
          : 'true'}
        type=${hasSubmitOnClickAction ? 'submit' : 'button'}
        @click=${ifDefined(blockActions.onClick)}
      >
        ${buttonContent}
      </button>
    </div>
  `;
}

const buttonBlockHelpers: BlockHelpers = {
  addBlockActions,
  defaultStyles,
  render,
  styleElements: BUTTON_BLOCK_STYLE_ELEMENTS,
  textElements: BUTTON_BLOCK_TEXT_ELEMENTS,
};

export default buttonBlockHelpers;
