/* eslint-disable no-param-reassign */
import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import {
  BLOCK_STYLE_ELEMENT,
  DEFAULT_COLORS,
  PARAGRAPH_TEXT_ELEMENT,
  STYLE_RULE_NAMES,
  SYSTEM_FONT_FAMILY_STYLE,
  TEXT_ELEMENT_TYPES,
  TEXT_STYLE_ELEMENT,
} from '../constants';
import renderEditorNode from '../development/editorNode';
import {
  AddBlockActionsProps,
  BlockHelpers,
  BlockRenderPackage,
} from '../types';

export type TextBlockTextElements = typeof TEXT_BLOCK_TEXT_ELEMENTS;

const TEXT_BLOCK_TEXT_ELEMENTS = {
  ...PARAGRAPH_TEXT_ELEMENT,
} as const;

const TEXT_BLOCK_STYLE_ELEMENTS = {
  ...TEXT_STYLE_ELEMENT,
  ...BLOCK_STYLE_ELEMENT,
} as const;

const defaultStyles = {
  [TEXT_BLOCK_STYLE_ELEMENTS.BLOCK]: {
    mobile: {
      [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
      [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.OPACITY]: '1',
      [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
      // private
      [STYLE_RULE_NAMES.POSITION]: 'relative',
    },
  },
  [TEXT_BLOCK_STYLE_ELEMENTS.TEXT]: {
    mobile: {
      // default variant uses the following styles
      [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
      [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
      [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
      [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
      [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
      [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
      [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
      // private
      [STYLE_RULE_NAMES.LINE_HEIGHT]: '1.2',
      [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
    },
  },
};

const addBlockActions = ({
  block,
  blockActions,
  popupActions,
  undeletableIds,
}: AddBlockActionsProps) => {
  blockActions.onClick = () => popupActions.handleButtonClick(block);

  if (!undeletableIds?.includes(block.id))
    blockActions.deleteBlock = () => popupActions.deleteBlock?.(block.id);
};

function render(renderData: BlockRenderPackage) {
  const { blockActions, content, classes, environment } = renderData;

  const {
    block: blockClasses,
    editorNode: editorNodeClasses,
    text: textClasses,
  } = classes;
  const textContent = content[TEXT_ELEMENT_TYPES.PARAGRAPH] ?? '';

  return html`
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(environment.isDevelopment ? 'true' : undefined)}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${environment.isDevelopment
        ? renderEditorNode(editorNodeClasses, blockActions.deleteBlock)
        : nothing}
      <p class=${classMap(textClasses)}>${textContent}</p>
    </div>
  `;
}

const textBlockHelpers: BlockHelpers = {
  addBlockActions,
  defaultStyles,
  render,
  styleElements: TEXT_BLOCK_STYLE_ELEMENTS,
  textElements: TEXT_BLOCK_TEXT_ELEMENTS,
};

export default textBlockHelpers;
