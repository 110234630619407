import { BlockPopup, CustomFont, ScreenSize } from '../../types';
import { getUniqueFontFamiliesInPopup } from './common';
import { loadCustomFontFamilies } from './custom';
import { loadGoogleFontFamilies } from './google';

export const loadFontsForPopup = async (
  { stepBlocks, teaserBlocks, theme }: BlockPopup,
  viewport: ScreenSize,
  customFonts: CustomFont[],
): Promise<void> => {
  const fontFamiliesInPopup = getUniqueFontFamiliesInPopup({
    stepBlocks,
    teaserBlocks,
    theme,
    viewport,
  });

  await loadCustomFontFamilies(fontFamiliesInPopup, customFonts);

  loadGoogleFontFamilies(fontFamiliesInPopup);
};
