/* eslint-disable no-param-reassign */
import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import {
  BLOCK_STYLE_ELEMENT,
  DEFAULT_COLORS,
  IMAGE_STYLE_ELEMENT,
  STYLE_RULE_NAMES,
} from '../constants';
import renderEditorNode from '../development/editorNode';
import {
  AddBlockActionsProps,
  BlockHelpers,
  BlockRenderPackage,
} from '../types';

export type ImageBlockStyleElements = typeof IMAGE_BLOCK_STYLE_ELEMENTS;

const IMAGE_BLOCK_STYLE_ELEMENTS = {
  ...BLOCK_STYLE_ELEMENT,
  ...IMAGE_STYLE_ELEMENT,
} as const;

const defaultStyles = {
  [IMAGE_BLOCK_STYLE_ELEMENTS.BLOCK]: {
    mobile: {
      [STYLE_RULE_NAMES.ALIGN_SELF]: 'center',
      [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.OPACITY]: '1',
      [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.WIDTH]: '100px',
      // private
      [STYLE_RULE_NAMES.POSITION]: 'relative',
    },
  },
  [IMAGE_BLOCK_STYLE_ELEMENTS.IMAGE]: {
    mobile: {
      [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
      [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
      [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
      [STYLE_RULE_NAMES.HEIGHT]: 'auto',
      [STYLE_RULE_NAMES.MAX_WIDTH]: '100%',
      // private
      [STYLE_RULE_NAMES.DISPLAY]: 'block',
      [STYLE_RULE_NAMES.WIDTH]: '100%',
    },
  },
};

const addBlockActions = ({
  block,
  blockActions,
  popupActions,
  undeletableIds,
}: AddBlockActionsProps) => {
  if (!undeletableIds?.includes(block.id))
    blockActions.deleteBlock = () => popupActions.deleteBlock?.(block.id);
};

function render(renderData: BlockRenderPackage) {
  const { blockActions, classes, environment } = renderData;
  const {
    block: blockClasses,
    image: imageClasses,
    editorNode: editorNodeClasses,
  } = classes;

  return html`
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(environment.isDevelopment ? 'true' : undefined)}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${environment.isDevelopment
        ? renderEditorNode(editorNodeClasses, blockActions.deleteBlock)
        : nothing}
      <img
        src=${ifDefined(renderData.block.config?.src)}
        class=${classMap(imageClasses)}
        alt=${renderData.block.config?.altText ?? ''}
      />
    </div>
  `;
}

const imageBlockHelpers: BlockHelpers = {
  addBlockActions,
  defaultStyles,
  render,
  styleElements: IMAGE_BLOCK_STYLE_ELEMENTS,
};

export default imageBlockHelpers;
