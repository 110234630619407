/* eslint-disable @typescript-eslint/no-explicit-any */
import { captureException } from '@sentry/browser';
import { applyDefaultsToPopups, camelCaseKeys, getUrlForShop } from './helper';
import { Popup } from '../../widget/types/popup';
import { DEFAULT_API_VALUES, LOWERCASE_POPUP_TYPES } from './constants';
import { API_GATEWAY_BASE_URL } from '../../helpers/utility';
import { LowerCasePopupType } from './types';

export const getAllPopupsByType = async (
  shopId: number,
  popupType: LowerCasePopupType,
): Promise<Popup[]> => {
  const url = getUrlForShop(shopId, popupType);

  let popups;

  try {
    const res = await fetch(url.toString());

    if (!res.ok) {
      throw new Error(`Fetch failed with status code ${res.status}`);
    }

    const data = await res.json();
    popups = data.popups;

    // Process popups with defaults only if the fetch is successful
    return applyDefaultsToPopups(popups, popupType);
  } catch (error) {
    captureException(new TypeError('Failed to load popups', { cause: error }));

    return [];
  }
};

export const getPopupVariables = async (
  shopId: number,
  popupType: LowerCasePopupType = LOWERCASE_POPUP_TYPES.DESKTOP,
  { params } = {} as { params?: Record<string, string> },
): Promise<Record<string, any>> => {
  const url = new URL(
    `/public/popups/${shopId}/${popupType}`,
    API_GATEWAY_BASE_URL,
  );

  if (params) {
    Object.entries(params).forEach(([key, value]) =>
      url.searchParams.append(key, value),
    );
  }

  const response = await fetch(url.toString());
  const { data } = await response.json();

  const valuesWithDefaults = {
    ...DEFAULT_API_VALUES,
    ...data,
    button_radius: data.button_radius,
    font: data.font || DEFAULT_API_VALUES.font[popupType],
  };

  return camelCaseKeys(valuesWithDefaults);
};
