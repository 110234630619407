export const DEFAULT_API_VALUES = {
  collection_attributes: ['phone'],
  font: {
    desktop: 'Roboto',
    mobile: 'Open Sans Condensed:300',
  },
  background_style: 'Color',
  button_radius: 10,
  two_touch_enabled: true,
};

export const LOWERCASE_POPUP_TYPES = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
} as const;
