/* eslint-disable no-underscore-dangle */
import intlTelInput from 'intl-tel-input';
import { LitElement } from 'lit';
import { StyleRules } from '../types';
import { HtmlSafeId } from '../types/render';

class PhoneInputWrapper extends LitElement {
  declare inputId: HtmlSafeId;

  declare countryCode?: string;

  declare phoneInputInstance: intlTelInput.Plugin | null;

  declare finalInputStyleRules: StyleRules;

  declare input: HTMLInputElement | null;

  phoneInputBindingTimeout: null | number = null;

  static get properties() {
    return {
      inputId: { type: String },
      countryCode: { type: String },
      phoneInputInstance: { type: Object, state: true },
      finalInputStyleRules: { type: Object },
    };
  }

  protected createRenderRoot() {
    return this;
  }

  getFormattedInputValue(): string | undefined {
    return this.phoneInputInstance?.getNumber();
  }

  startPhoneInputBindingTimeout() {
    this.phoneInputBindingTimeout = window.setTimeout(() => {
      this.phoneInputBindingTimeout = null;
    }, 5000);
  }

  /* Continually attempts to initialize the iti phone instance after every
  animation frame if the phone instance is in DOM and has a computed
  width. We use a connected callback timeout to prevent infinite loop. */
  setPhoneInputInstance = () => {
    if (!this.phoneInputBindingTimeout) return;
    if (this.input && this.input.offsetWidth > 0) {
      this.phoneInputInstance = intlTelInput(this.input, {
        autoPlaceholder: 'off',
        dropdownContainer: document.body,
        initialCountry: this.countryCode ?? 'US',
        preferredCountries: ['us', 'ca'],
        separateDialCode: true,
        utilsScript:
          'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js',
      });
      this.phoneInputBindingTimeout = null;
    } else {
      requestAnimationFrame(this.setPhoneInputInstance);
    }
  };

  updatePhoneInput() {
    requestAnimationFrame(this.setPhoneInputInstance);
  }

  getValidationError(): string | null {
    if (this.phoneInputInstance?.isValidNumber()) return null;

    const validationError = this.phoneInputInstance?.getValidationError();

    switch (validationError) {
      case intlTelInputUtils.validationError.TOO_SHORT:
        return 'Phone number is too short';
      case intlTelInputUtils.validationError.TOO_LONG:
        return 'Phone number is too long';
      default:
        return 'Invalid phone number';
    }
  }

  protected firstUpdated(
    _changedProperties: Map<string | number | symbol, unknown>,
  ): void {
    super.firstUpdated(_changedProperties);
    this.updatePhoneInput();
  }

  // The dropdown position breaks if the popup becomes scrollable so we need to force close it on scroll
  // See https://github.com/jackocnr/intl-tel-input?tab=readme-ov-file#troubleshooting for more details
  static closeDropdownOnScroll(): void {
    window.dispatchEvent(
      new Event('scroll', {
        cancelable: true,
        bubbles: true,
      }),
    );
  }

  /*
  intl-tel-input modifies the dom after it initializes and needs to apply some inline styles
  to the phone input to get the country dropdown sized appropriately. Notably it sets a margin-bottom: 0 !important
  which conflicts with user specified styles. The two functions below will copy the styles from the input to the
  render root to ensure that the styles are applied as specified by the user.
  */
  copyInputBlockFinalStyleRules() {
    const renderRoot = this.renderRoot as HTMLElement;
    const { margin } = this.finalInputStyleRules;
    renderRoot.style.margin = margin;
  }

  copyInputComputedStyle() {
    if (!this.input) return null;

    (this.renderRoot as HTMLElement).style.margin = getComputedStyle(
      this.input,
    ).margin;
  }

  // The any is not ideal here but is needed to fix conflicts with differing SDK and FE versions of lit
  protected updated(_changedProperties: any): void {
    super.updated(_changedProperties);
    this.copyInputBlockFinalStyleRules();
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.startPhoneInputBindingTimeout();
    this.input = this.renderRoot.querySelector(`.${this.inputId}`);

    this.copyInputComputedStyle();

    document
      .querySelector('form')
      ?.addEventListener('scroll', PhoneInputWrapper.closeDropdownOnScroll);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.phoneInputInstance?.destroy();
    document
      .querySelector('form')
      ?.removeEventListener('scroll', PhoneInputWrapper.closeDropdownOnScroll);

    if (this.phoneInputBindingTimeout)
      clearTimeout(this.phoneInputBindingTimeout);
  }
}

if (!customElements.get('ps-phone-input-block-iti-wrapper')) {
  customElements.define('ps-phone-input-block-iti-wrapper', PhoneInputWrapper);
}
