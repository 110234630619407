/* eslint-disable no-param-reassign */
import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import {
  BLOCK_STYLE_ELEMENT,
  DEFAULT_COLORS,
  STYLE_RULE_NAMES,
} from '../constants';
import renderEditorNode from '../development/editorNode';
import {
  AddBlockActionsProps,
  Block,
  BlockHelpers,
  BlockRenderPackage,
} from '../types';

const ROOT_BLOCK_STYLE_ELEMENTS = {
  ...BLOCK_STYLE_ELEMENT,
};

const defaultStyles = {
  [ROOT_BLOCK_STYLE_ELEMENTS.BLOCK]: {
    mobile: {
      [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.POPUP_BG,
      [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.LAYOUT_DIRECTION]: 'column',
      [STYLE_RULE_NAMES.LAYOUT_SPACING]: 'center',
      [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.MAX_WIDTH]: '340px',
      [STYLE_RULE_NAMES.MIN_HEIGHT]: '240px',
      [STYLE_RULE_NAMES.OPACITY]: '1',
      [STYLE_RULE_NAMES.PADDING]: '30px 30px 30px 30px',
      // private
      [STYLE_RULE_NAMES.DISPLAY]: 'flex',
      [STYLE_RULE_NAMES.POSITION]: 'relative',
      [STYLE_RULE_NAMES.WIDTH]: '100%',
    },
    desktop: {
      [STYLE_RULE_NAMES.MAX_WIDTH]: '450px',
    },
  },
};

const addBlockActions = ({
  block,
  blockActions,
  popupActions,
  undeletableIds,
}: AddBlockActionsProps) => {
  if (!undeletableIds?.includes(block.id))
    blockActions.deleteBlock = () => popupActions.deleteBlock?.(block.id);
};

function isEmpty({ children = [] }: Block) {
  return children.length === 0;
}

function render(renderData: BlockRenderPackage) {
  const { blockActions, classes, environment } = renderData;
  const { block: blockClasses, editorNode: editorNodeClasses } = classes;

  return html`
    <form
      class=${classMap(blockClasses)}
      novalidate
      @click=${ifDefined(blockActions.selectBlock)}
      @submit=${(event: SubmitEvent) => event.preventDefault()}
    >
      ${environment.isDevelopment
        ? renderEditorNode(editorNodeClasses, blockActions.deleteBlock)
        : nothing}
      ${renderData.children}
    </form>
  `;
}

const rootBlockHelpers: BlockHelpers = {
  addBlockActions,
  defaultStyles,
  isEmpty,
  render,
  styleElements: ROOT_BLOCK_STYLE_ELEMENTS,
};

export default rootBlockHelpers;
