import WebFont from 'webfontloader';

export const GOOGLE_FONT_FAMILIES = {
  ROBOTO: 'Roboto',
  OPEN_SANS: 'Open Sans',
  LATO: 'Lato',
  MONTSERRAT: 'Montserrat',
  OSWALD: 'Oswald',
  POPPINS: 'Poppins',
  RUBIK: 'Rubik',
  QUICKSAND: 'Quicksand',
  PLAYFAIR_DISPLAY: 'Playfair Display',
  FRAUNCES: 'Fraunces',
} as const;

export const isGoogleFontFamily = (fontFamily: string) =>
  (Object.values(GOOGLE_FONT_FAMILIES) as string[]).includes(fontFamily);

export const loadGoogleFontFamilies = (fontFamilies: string[]) => {
  const googleFontFamilies = fontFamilies.filter(isGoogleFontFamily);

  if (googleFontFamilies.length) {
    WebFont.load({
      classes: false,
      events: false,
      google: {
        families: googleFontFamilies,
      },
    });
  }
};
