import {
  GENERAL_REQUIRED_ERROR_MESSAGE,
  RESERVED_DATA_NAMES,
} from '../constants';
import { Block } from '../types';

const SUBSCRIBER_PROPERTY_PREFIX = 'subscriber_property_';

export const createSubscriberPropertyDataName = (dataName: string) =>
  `${SUBSCRIBER_PROPERTY_PREFIX}${dataName}`;

export const isSubscriberPropertyDataName = (dataName: string) =>
  dataName.startsWith(SUBSCRIBER_PROPERTY_PREFIX) ||
  dataName === RESERVED_DATA_NAMES.EMAIL;

export const removeSubscriberPropertyPrefix = (dataName: string) =>
  dataName.replace(SUBSCRIBER_PROPERTY_PREFIX, '');

export const validateRequired = <T>(
  block: Block,
  data: T,
  errorMessage = GENERAL_REQUIRED_ERROR_MESSAGE,
): string | null => {
  const isRequired = !!block.config?.required;
  const hasData = !!data;
  return isRequired && !hasData ? errorMessage : null;
};

export const validateStringLength = (
  data: string,
  length: number,
  errorMessage = `This field must be ${length} characters long`,
): string | null => {
  const hasRequiredLength = data.length === length;
  return !hasRequiredLength ? errorMessage : null;
};

export const validateEmailFormat = (
  data: string,
  errorMessage = 'Enter a valid email address',
): string | null => {
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  const isValidEmail = emailRegex.test(data);
  return !isValidEmail ? errorMessage : null;
};

/**
 * Removes all non-numeric characters from OTP code
 */
export const getSanitizedOtpValue = (data: string): string =>
  data.replace(/\D/g, '');
