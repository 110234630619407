import BlockHelpers from '../blocks';
import { Block, BlockContentForRender } from '../types';

/*
  Gets the text content for each of a block's content elements.
  For now, we only have one potential piece of content for each of a block's content elements,
  which we call the `default` content. In the future, we might support mulitple versions of
  of a piece of text content, and this code would figure out which version to use.

  Example:
  ```
  const block = {
    id: '123',
    version: 1,
    type: 'title',
    content: {
      title: {
        default: 'Hello, world!',
      },
    },
  };

  const content = getBlockContent(block); // content.title === 'Hello, world!'
  ```
*/
export const getBlockContent = (block: Block) => {
  const textElements = BlockHelpers.getTextElements(block.type);

  if (!block.content || !textElements) {
    return {};
  }

  const blockTextElements = Object.values(textElements);

  return blockTextElements.reduce<BlockContentForRender>((acc, element) => {
    // eslint-disable-next-line no-param-reassign
    acc[element] = block.content?.[element]?.default || '';
    return acc;
  }, {});
};
