import { captureException } from '@sentry/browser';
import { BlockPopupStateManager } from '.';
import {
  hasFlag,
  POPUP_BLOCK_EDITOR_FLAG,
  POPUP_STATUS_COOKIE_NAME_PREFIX,
  POPUP_TRIGGER_TYPES,
} from '../../../../helpers/constants';
import { getCookieValueByKey } from '../../../../helpers/utility';
import {
  getBlockPopupByTriggerType,
  getValidBlockPopups,
} from '../../../../services/popup/helper';
import { ScreenSize } from '../third-party-dep/types';
import { getBlockPopups } from './utils/api';
import { configStateStore } from '../../helpers/stateManager';
import { loadGoogleFontsForPopup } from './utils/fonts';

const initializeBlockPopups = async (
  statuses: string,
  currentCountry: string,
  origin: string,
  sessionId: string,
  subscriberId: string,
  shopId: number,
  viewport: ScreenSize,
): Promise<boolean> => {
  const hasBlockPopupFlag = hasFlag(POPUP_BLOCK_EDITOR_FLAG);
  const { disclaimer } = configStateStore.getState();

  if (!hasBlockPopupFlag || !disclaimer) return false;

  /* If the block popup flag is set, attempt to render the block popups, and
  return early so the v2 popups aren't fetched. */
  try {
    const blockPopups = await getBlockPopups(shopId);

    const validBlockPopups = getValidBlockPopups({
      blockPopups,
      currentCountry,
      origin,
      statuses,
      subscriberId,
    });

    const timeDelayBlockPopup = getBlockPopupByTriggerType(
      validBlockPopups,
      POPUP_TRIGGER_TYPES.DELAY,
    );

    if (!timeDelayBlockPopup) {
      return false;
    }

    // Start loading fonts as soon as possible
    loadGoogleFontsForPopup(timeDelayBlockPopup, viewport);

    const timeDelayBlockPopupStatus = getCookieValueByKey(
      POPUP_STATUS_COOKIE_NAME_PREFIX,
      timeDelayBlockPopup.id,
      statuses,
    );

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const timeDelayBlockPopupStateManager = new BlockPopupStateManager({
      currentCountry,
      disclaimer,
      origin,
      popup: timeDelayBlockPopup,
      sessionId,
      shopId,
      status: timeDelayBlockPopupStatus,
      subscriberId,
      theme: timeDelayBlockPopup.theme,
      viewport,
    });

    return true;
  } catch (error) {
    // no op
    // eslint-disable-next-line no-console
    console.error(`Error initializing block popups: ${error}`);
    captureException(error);
    return false;
  }
};

export default initializeBlockPopups;
