/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { html, nothing, unsafeCSS } from 'lit';
import SDKBlockHelpers from '../blocks';
import {
  BLOCK_TYPES,
  OTP_VERIFY_ERROR_STYLES,
  STYLE_ELEMENT_TYPES,
} from '../constants';
import renderDropZone from '../development/dropZone';
import {
  Block,
  BlockActions,
  BlockClasses,
  BlockRenderPackage,
  BlockState,
  PopupActions,
  RenderContext,
} from '../types';
import { getBlockContent } from './content';
import {
  createBasePopupCSS,
  createBlockInstanceStyleElementClass,
  createEditorCSS,
  createInstanceCSSForBlocks,
  getLayoutDirection,
} from './styles';
import { HtmlSafeId } from '../types/render';

const addEditorActions = (
  block: Block,
  popupActions: PopupActions,
  blockActions: BlockActions,
) => {
  if (popupActions.selectBlock) {
    // We need to do this weird reassignment otherwise TS doesn't know
    // that blockActions.selectBlock cannot possibly be undefined here. This way
    // we can call it without checking for undefined a couple lines below.
    const fn = popupActions.selectBlock;
    blockActions.selectBlock = (event: Event) => {
      event.stopPropagation();
      fn(block.id);
    };
  }

  if (popupActions.handleReorderDragStart) {
    const fn = popupActions.handleReorderDragStart;
    blockActions.handleReorderDragStart = (event: DragEvent) => {
      fn(event, block.id);
    };
  }

  if (popupActions.handleReorderDragEnd) {
    blockActions.handleReorderDragEnd = popupActions.handleReorderDragEnd;
  }

  if (popupActions.setEmailIntegration) {
    blockActions.setEmailIntegration = popupActions.setEmailIntegration;
  }
};

function getBlockClasses(block: Block, renderContext: RenderContext) {
  const {
    blockState,
    environment: { isDevelopment },
  } = renderContext;
  const styleElements = SDKBlockHelpers.getStyleElements(block.type);

  const isContainer = block.type === BLOCK_TYPES.CONTAINER;
  const isRootBlock = block.type === BLOCK_TYPES.ROOT;
  const isTeaserRootBlock = block.type === BLOCK_TYPES.TEASER_ROOT;
  const layoutDirection = getLayoutDirection(block, renderContext);
  const isHorizontalLayout = layoutDirection === 'row';
  const isVerticalLayout = layoutDirection === 'column';

  const developmentBlockClasses = {
    container: isContainer,
    'root-block': isRootBlock,
    'teaser-root-block': isTeaserRootBlock,
    'empty-layout': SDKBlockHelpers.isEmpty(block),
    'horizontal-layout': isHorizontalLayout,
    'vertical-layout': isVerticalLayout,
  };

  const editorNodeClasses = {
    'editor-node': true,
    error: !!blockState[block.id]?.error,
    selected: !!blockState[block.id]?.selected,
  };

  const baseClasses = { editorNode: editorNodeClasses };

  return Object.values(styleElements).reduce<BlockClasses>(
    (acc, styleElement) => {
      const isBlockStyleElement = styleElement === STYLE_ELEMENT_TYPES.BLOCK;
      const instanceBlockStyleElementClass =
        createBlockInstanceStyleElementClass(block, styleElement);

      return {
        ...acc,
        [styleElement]: {
          ...(isDevelopment && isBlockStyleElement
            ? developmentBlockClasses
            : {}),
          [instanceBlockStyleElementClass]: true,
        },
      };
    },
    baseClasses,
  );
}

function getBlockActions(block: Block, renderContext: RenderContext) {
  const { popupActions, environment } = renderContext;
  const blockActions: BlockActions = {};

  if (environment.isDevelopment) {
    addEditorActions(block, popupActions, blockActions);
  }

  SDKBlockHelpers.addBlockActions({
    block,
    blockActions,
    environment,
    popupActions,
    stepBlocks: renderContext.stepBlocks,
    undeletableIds: renderContext.undeletableIds,
  });

  return blockActions;
}

function renderChildren(block: Block, renderContext: RenderContext) {
  if (!block.children) {
    return null;
  }

  const children = block.children.map((childId) => {
    const childBlock = renderContext.blocks.find(
      (block) => block.id === childId,
    );

    if (!childBlock) {
      throw new Error(`Block with id ${childId} not found`);
    }

    return renderBlock(childBlock, renderContext);
  }, []);

  if (
    renderContext.environment.isDevelopment &&
    (block.type === BLOCK_TYPES.CONTAINER || block.type === BLOCK_TYPES.ROOT)
  ) {
    const firstDropZone = renderDropZone(renderContext, block.id, 0);

    return children.reduce(
      (acc, child, index) => {
        const dropZone = renderDropZone(renderContext, block.id, index + 1);
        acc.push(child, dropZone);

        return acc;
      },
      [firstDropZone],
    );
  }

  return children;
}

const renderBlock = (block: Block, renderContext: RenderContext) => {
  const classes = getBlockClasses(block, renderContext);
  const content = getBlockContent(block);
  const children = renderChildren(block, renderContext);
  const blockActions = getBlockActions(block, renderContext);
  const state = renderContext.blockState[block.id];

  const renderData: BlockRenderPackage = {
    blockActions,
    block,
    children,
    classes,
    content,
    disclaimer: renderContext.disclaimer,
    environment: renderContext.environment,
    state,
  };

  return SDKBlockHelpers.render(renderData);
};

/* For closed beta, we're just going to render errors with a static style. */
export const renderError = (
  blockState: BlockState | undefined,
  inputId: HtmlSafeId,
) => {
  const { error } = blockState ?? {};
  if (!error) return nothing;

  return html`<label
    for=${inputId}
    id=${`${inputId}-error`}
    style=${OTP_VERIFY_ERROR_STYLES}
    >${error}</label
  >`;
};

export const renderResendOtpSuccessMessage = (
  blockState: BlockState | undefined,
  inputId: HtmlSafeId,
) => {
  const { isResendOtpSuccessVisible } = blockState ?? {};
  if (!isResendOtpSuccessVisible) return nothing;
  return html`<span id=${`${inputId}-error`}>Code sent!</span>`;
};

export const renderPopup = (renderContext: RenderContext) => {
  const rootBlockOrTeaserRootBlock = renderContext.blocks.find(
    (block) =>
      block.type === BLOCK_TYPES.ROOT || block.type === BLOCK_TYPES.TEASER_ROOT,
  );

  if (!rootBlockOrTeaserRootBlock) {
    throw new Error('No root block found');
  }

  /*
    @TODO - Could we memoize the CSS creation somehow, when not in the editor?
    Or mount it to the DOM outside of the render function so we don't have to re-render it every time?

    @TODO - Support multiple breakpoints/screen sizes - for now just doing mobile as thats the default size
  */
  const blockInstanceCSS = createInstanceCSSForBlocks(
    renderContext.blocks,
    renderContext.environment.viewport,
    renderContext.theme,
  );

  return html`
    <style>
      ${createBasePopupCSS()}
      ${renderContext.environment.isDevelopment ? createEditorCSS() : nothing}
      ${unsafeCSS(blockInstanceCSS)}
    </style>

    ${renderBlock(rootBlockOrTeaserRootBlock, renderContext)}
  `;
};
