import { CONFIG_ONCLICK_ROUTE_DIRECTIONS } from '../../third-party-dep/constants';
import { Step, Block } from '../../third-party-dep/types';

export const getNextStep = ({
  block,
  currentStepId,
  isExistingSubscriber,
  steps,
}: {
  block: Block;
  currentStepId: string;
  steps: Step[];
  isExistingSubscriber?: boolean;
}): Step | undefined => {
  const { existingSubscriberRouteTo, routeTo } = block.config?.onClick ?? {};
  if (!existingSubscriberRouteTo && !routeTo) return;

  const hasRouteToNext = routeTo === CONFIG_ONCLICK_ROUTE_DIRECTIONS.NEXT;
  const hasRouteToPrevious =
    routeTo === CONFIG_ONCLICK_ROUTE_DIRECTIONS.PREVIOUS;

  // Existing subscriber route to specific step.
  if (isExistingSubscriber)
    return steps.find((step) => step.id === existingSubscriberRouteTo);

  // Route to next or previous
  if (hasRouteToNext || hasRouteToPrevious) {
    const currentStep = steps.find((step) => step.id === currentStepId);
    if (!currentStep) return;

    return steps.find(
      (step) => step.order === currentStep.order + (hasRouteToNext ? 1 : -1),
    );
  }

  // Route to specific step
  return steps.find((step) => step.id === routeTo);
};
