/* eslint-disable no-restricted-syntax */
/* eslint-disable arrow-body-style */
import SDKBlockHelpers from '../blocks';
import {
  GOOGLE_FONT_FAMILIES,
  POPUP_WRAPPER_ID,
  SCREEN_SIZES,
  STYLE_RULE_NAMES,
  STYLE_RULE_NAMES_TO_CSS,
} from '../constants';
import {
  Block,
  BlockStyleSources,
  ElementStyles,
  RenderContext,
  ScreenSize,
  StepBlock,
  StyleElementName,
  StyleElementVariant,
  StyleRuleName,
  StyleRules,
  StyleSources,
  TeaserBlock,
  Theme,
} from '../types';
import {
  FontFamilyCssValue,
  GoogleFontFamily,
  SystemFontFamily,
} from '../types/styles';
import { CustomStyleRulesToHandlers } from './styleRules';

export const createBlockInstanceStyleElementClass = (
  block: Block,
  styleElement: string,
) => {
  return `${styleElement}-${block.id}`;
};

export const createDropZoneClass = (blockId: string, index: number) => {
  return `drop-zone-${blockId}-${index}`;
};

export const mapStyleRuleToCSS = (
  styleRuleName: StyleRuleName,
  styleRuleValue: string,
): string | undefined => {
  const cssRule = STYLE_RULE_NAMES_TO_CSS[styleRuleName];

  if (cssRule) {
    return `${cssRule}: ${styleRuleValue};`;
  }

  if (CustomStyleRulesToHandlers[styleRuleName]) {
    return CustomStyleRulesToHandlers[styleRuleName](styleRuleValue);
  }
};

export const createCSSFromStyleRules = (styleRules: StyleRules) => {
  return Object.entries(styleRules).reduce((acc, [ruleName, ruleValue]) => {
    const cssRule = mapStyleRuleToCSS(ruleName, ruleValue);

    if (cssRule) {
      return `${acc}${cssRule}`;
    }

    return acc;
  }, '');
};

export const groupStyleRules = (
  styleRules: StyleRules,
  variants: StyleElementVariant[] = [],
): {
  variant: StyleElementVariant | null;
  hover: StyleRules;
  focus: StyleRules;
  initial: StyleRules;
} => {
  let variant: StyleElementVariant | null = null;
  const hoverStyleRules: StyleRules = {};
  const focusStyleRules: StyleRules = {};
  const initialStyleRules: StyleRules = {};

  for (const [styleRuleName, styleRuleValue] of Object.entries(styleRules)) {
    if (styleRuleName === 'variant') {
      variant = variants.find((v) => v.id === styleRuleValue) || null;
    } else if (styleRuleName.startsWith('hover')) {
      // No op
    } else if (styleRuleName.startsWith('focus')) {
      // No op
    } else {
      initialStyleRules[styleRuleName] = styleRuleValue;
    }
  }

  return {
    variant,
    hover: hoverStyleRules,
    focus: focusStyleRules,
    initial: initialStyleRules,
  };
};

export const flattenElementStyles = (
  elementStyles: ElementStyles | undefined,
  currentScreenSize: ScreenSize,
): StyleRules => {
  const screenSizeOrder: ScreenSize[] = ['mobile', 'desktop'];
  let flattenedStyleRules: StyleRules = {};

  for (const screenSize of screenSizeOrder) {
    const currentStyleRules = elementStyles?.[screenSize] || {};

    flattenedStyleRules = {
      ...flattenedStyleRules,
      ...currentStyleRules,
    };

    if (screenSize === currentScreenSize) {
      return flattenedStyleRules;
    }
  }

  return flattenedStyleRules;
};

export const combineStyleSources = (styleSources: StyleSources): StyleRules => {
  return {
    ...styleSources.default,
    ...(styleSources.variant?.styles || {}),
    ...styleSources.instance,
  };
};

export const getBlockStyleSources = (
  block: Block,
  screenSize: ScreenSize,
  theme: Theme,
): BlockStyleSources => {
  const blockStyles: BlockStyleSources = {};

  const styleElements = SDKBlockHelpers.getStyleElements(block.type);
  const defaultStyles = SDKBlockHelpers.getDefaultStyles(block.type);
  const instanceStyles = block.styles;

  for (const styleElementName of Object.values(styleElements)) {
    const defaultStylesForElement = defaultStyles[styleElementName];
    const instanceStylesForElement = instanceStyles?.[styleElementName];
    const variantsForElement = theme[styleElementName];

    const flattenedDefaultStyles = flattenElementStyles(
      defaultStylesForElement,
      screenSize,
    );

    const flattenedInstanceStyles = flattenElementStyles(
      instanceStylesForElement,
      screenSize,
    );

    const { initial: initialDefaultStyleRules } = groupStyleRules(
      flattenedDefaultStyles,
      variantsForElement,
    );

    const { initial: initialInstanceStyleRules, variant } = groupStyleRules(
      flattenedInstanceStyles,
      variantsForElement,
    );

    blockStyles[styleElementName] = {
      default: initialDefaultStyleRules,
      variant,
      instance: initialInstanceStyleRules,
    };
  }

  return blockStyles;
};

/**
 * Returns the final style rules object for a block in a specific viewport.
 *
 * Example return:
 * ```
 * { label: { fontSize: '16px', textAlign: 'center' } }
 * ```
 */
export const getFinalStyleRulesForBlock = (
  block: Block,
  theme: Theme,
  viewport: ScreenSize,
): Partial<Record<StyleElementName, StyleRules>> => {
  const styleElementNames = Object.values(
    SDKBlockHelpers.getStyleElements(block.type),
  );

  const styleSources = getBlockStyleSources(block, viewport, theme);

  const finalStyleRulesForBlock: Partial<Record<StyleElementName, StyleRules>> =
    {};

  styleElementNames.forEach((styleElementName) => {
    const styleSource = styleSources[styleElementName];

    if (styleSource) {
      const combinedStyles = combineStyleSources(styleSource);

      finalStyleRulesForBlock[styleElementName] = combinedStyles;
    }
  });

  return finalStyleRulesForBlock;
};

type FinalStyleRulesForPopupOptions = {
  stepBlocks: StepBlock[];
  teaserBlocks: TeaserBlock[];
  theme: Theme;
  viewport?: ScreenSize;
};

/**
 * Returns an object with a viewport(s) as key, and an array of final style rule
 * objects for each block in a popup for that viewport as value. By default,
 * both viewports are returned, but a specific one can be targeted.
 *
 * Example return:
 * ```
 *   {
 *      mobile: [
 *        { button: { fontSize: '16px' } },
 *        { input: { color: '#000000' } }
 *     ],
 *      desktop: [
 *        { button: { fontSize: '16px' } },
 *        { input: { color: '#FFFFFF' } }
 *      ],
 *   }
 * ```
 */
export const getFinalStyleRulesForPopup = ({
  stepBlocks,
  teaserBlocks,
  theme,
  viewport,
}: FinalStyleRulesForPopupOptions): Partial<
  Record<ScreenSize, Partial<Record<StyleElementName, StyleRules>>[]>
> => {
  const shouldReturnMobile = !viewport || viewport === SCREEN_SIZES.MOBILE;
  const shouldReturnDesktop = !viewport || viewport === SCREEN_SIZES.DESKTOP;

  return {
    [SCREEN_SIZES.MOBILE]: shouldReturnMobile
      ? [...stepBlocks, ...teaserBlocks].map((block) =>
          getFinalStyleRulesForBlock(block, theme, SCREEN_SIZES.MOBILE),
        )
      : undefined,
    [SCREEN_SIZES.DESKTOP]: shouldReturnDesktop
      ? [...stepBlocks, ...teaserBlocks].map((block) =>
          getFinalStyleRulesForBlock(block, theme, SCREEN_SIZES.DESKTOP),
        )
      : undefined,
  };
};

// Narrows type of string being returned in `getUniqueStyleRuleValuesInPopup`
type StringOrFontFamilyStyleArr<T extends StyleRuleName> =
  T extends typeof STYLE_RULE_NAMES.FONT_FAMILY
    ? FontFamilyCssValue[]
    : string[];

/**
 * Returns an array of unique CSS values for a given style rule name from a
 * popup's blocks. By default, both viewports are considered, but a specific one
 * can be targeted.
 *
 * Example return:
 * ```
 * ['flex-start', 'center', 'flex-end']
 * ```
 */
export const getUniqueStyleRuleValuesInPopup = <T extends StyleRuleName>({
  stepBlocks,
  styleRuleName,
  teaserBlocks,
  theme,
  viewport,
}: FinalStyleRulesForPopupOptions & {
  styleRuleName: StyleRuleName;
}): StringOrFontFamilyStyleArr<T> => {
  const { mobile = [], desktop = [] } = getFinalStyleRulesForPopup({
    stepBlocks,
    teaserBlocks,
    theme,
    viewport,
  });

  const uniqueStyleRuleValues = new Set<FontFamilyCssValue | string>();

  [...mobile, ...desktop].forEach((block) => {
    Object.values(block).forEach((styleRuleObj) => {
      const cssValue = styleRuleObj[styleRuleName];
      if (cssValue) uniqueStyleRuleValues.add(cssValue);
    });
  });

  return [...uniqueStyleRuleValues] as StringOrFontFamilyStyleArr<T>;
};

/**
 * Returns whatever string is enclosed in single quotes from a `font-family` CSS
 * declaration. If no single quotes are found, or nothing is found inside them,
 * an empty string is returned.
 *
 * Example return: `'Montserrat'`
 */
export const separateFontFamilyFromFallback = (
  fontFamilyString: FontFamilyCssValue,
) => {
  const match = fontFamilyString.match(/'([^']*)'/);
  return match ? (match[1] as GoogleFontFamily | SystemFontFamily) : '';
};

export const isGoogleFontFamilyStyle = (
  fontFamilyStyle: FontFamilyCssValue,
) => {
  const fontFamily = separateFontFamilyFromFallback(fontFamilyStyle);
  return (Object.values(GOOGLE_FONT_FAMILIES) as string[]).includes(fontFamily);
};

export const isGoogleFontFamily = (
  fontFamily: SystemFontFamily | GoogleFontFamily | '',
) => {
  return (Object.values(GOOGLE_FONT_FAMILIES) as string[]).includes(fontFamily);
};

export const getUniqueGoogleFontFamiliesInPopup = ({
  stepBlocks,
  teaserBlocks,
  theme,
  viewport,
}: FinalStyleRulesForPopupOptions): GoogleFontFamily[] => {
  const uniqueFontFamilyStyleRuleValues = getUniqueStyleRuleValuesInPopup({
    stepBlocks,
    teaserBlocks,
    styleRuleName: STYLE_RULE_NAMES.FONT_FAMILY,
    theme,
    viewport,
  });

  const uniqueFontFamilies = uniqueFontFamilyStyleRuleValues.map(
    (fontFamilyStyleRuleValue) =>
      separateFontFamilyFromFallback(fontFamilyStyleRuleValue),
  );

  const uniqueGoogleFontFamilies = uniqueFontFamilies.filter((fontFamily) =>
    isGoogleFontFamily(fontFamily),
  );

  return uniqueGoogleFontFamilies as GoogleFontFamily[];
};

export const createInstanceCSSForBlock = (
  block: Block,
  viewport: ScreenSize,
  theme: Theme,
): string => {
  let css = '';

  const finalStyleRules = getFinalStyleRulesForBlock(block, theme, viewport);

  Object.entries(finalStyleRules).forEach(([styleElementName, styleRules]) => {
    const selector = `.${createBlockInstanceStyleElementClass(
      block,
      styleElementName,
    )}`;

    const finalStyleRulesAsCss = createCSSFromStyleRules(styleRules);

    css += `${selector} { ${finalStyleRulesAsCss} }\n`;
  });

  return css;
};

export const createInstanceCSSForBlocks = (
  blocks: Block[],
  viewport: ScreenSize,
  theme: Theme,
): string => {
  return blocks.reduce((acc, block) => {
    const css = createInstanceCSSForBlock(block, viewport, theme);

    return css ? `${acc} ${css}` : acc;
  }, '');
};

/**
 * Get the layout direction for the block style element of a given block for a
 * given viewport size.
 */
export const getLayoutDirection = (
  block: Block,
  {
    environment: { viewport },
    theme,
  }: Pick<RenderContext, 'environment' | 'theme'>,
): string | undefined => {
  const {
    default: defaultSource,
    instance,
    variant,
  } = getBlockStyleSources(block, viewport, theme)?.block ?? {
    default: {},
    instance: {},
    variant: null,
  };

  const finalStyles = combineStyleSources({
    default: defaultSource,
    variant,
    instance,
  });

  return finalStyles.layoutDirection;
};

export const createBasePopupCSS = () => `
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  button {
    cursor: pointer;
  }
`;

export const createEditorCSS = () => `
  /* Vars */
  #${POPUP_WRAPPER_ID} {
    --drop-zone-size: 24px;
    --drop-zone-plus-size: 18px;
    --drop-zone-drag-border-size: 1px;
    --editor-node-button-size: 24px;
    --editor-node-selected-border-size: 2px;
    --editor-node-button-svg-size: calc(var(--editor-node-button-size) - 8px);
  }

  /* Editor node */
  .editor-node {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    outline: var(--editor-node-selected-border-size) solid var(--purple-core);
    position: absolute;
    top: 0;
    transition: opacity 0.1s;
    width: 100%;
  }

  .editor-node:hover {
    opacity: 1;
    z-index: 1;
  }

  .editor-node.selected,
  .editor-node.selected:hover {
    opacity: 1;
    z-index: 1;
  }

  /* Editor node button row */
  .editor-node__button-row {
    display: none;
  }

  .editor-node.selected > .editor-node__button-row {
    display: flex;
    justify-content: flex-end;
  }

  /* Editor node button */
  .editor-node__button {
    background-color: var(--purple-core);
    color: #fff;
    display: grid;
    height: var(--editor-node-button-size);
    margin-top: calc(
      -1 * var(--editor-node-button-size) - var(--editor-node-selected-border-size)
    );
    margin-right: calc(-1 * var(--editor-node-selected-border-size));
    place-content: center;
    width: var(--editor-node-button-size);
  }

  .editor-node__button-svg {
    height: var(--editor-node-button-svg-size);
    width: var(--editor-node-button-svg-size);
  }

  .root-block .editor-node,
  .container .editor-node {
    z-index: 0 !important;
  }

  /* Drop zone */
  .drop-zone {
    display: none;
    flex-shrink: 0;
    opacity: 0.5;
    position: relative;
    transition: 0.3s;
    transition-property: opacity;
  }

  .drop-zone.dragging {
    display: block;
    z-index: 1;
  }

  .drop-zone.dragging-over {
    opacity: 1;
    z-index: 2;
  }

  /* Drop zone dashed line */
  .drop-zone::before {
    content: '';
    display: block;
    height: var(--drop-zone-drag-border-size);
    left: 0;
    position: absolute;
    transition: transform 0.1s;
  }

  /* Drop zone plus */
  .drop-zone::after {
    background-color: white;
    border-radius: 50%;
    border: 2px solid var(--highlight-color);
    color: var(--highlight-color);
    content: '+';
    display: grid;
    height: var(--drop-zone-plus-size);
    left: 50%;
    line-height: 1;
    opacity: 0;
    place-content: center;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0); /* order matters */
    transition-property: opacity, transform;
    transition: 0.1s;
    width: var(--drop-zone-plus-size);
  }

  .drop-zone.dragging-over::after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    transition-delay: 0.15s;
  }

  /*  Drop zone in vertical layout */
  .vertical-layout > .drop-zone {
    height: var(--drop-zone-size);
    margin-block: calc(-1 * var(--drop-zone-size) / 2);
  }

  .vertical-layout > .drop-zone::before {
    border-top: var(--drop-zone-drag-border-size) dashed black;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  .vertical-layout > .drop-zone.dragging-over::before {
    border-color: var(--highlight-color);
    border-top-style: solid;
    transform: translateY(-50%) scaleX(1.03) scaleY(3); /* order matters */
  }

  /* Drop zone in horizontal layout */
  .horizontal-layout > .drop-zone {
    margin-inline: calc(-1 * var(--drop-zone-size) / 2);
    width: var(--drop-zone-size);
  }

  .horizontal-layout > .drop-zone::before {
    border-left: var(--drop-zone-drag-border-size) dashed black;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .horizontal-layout > .drop-zone.dragging-over::before {
    border-color: var(--highlight-color);
    border-left-style: solid;
    transform: translateX(-50%) scaleY(1.03) scaleX(3); /* order matters */
  }

  /* Empty layout */
  .empty-layout > .drop-zone {
    border-radius: var(--border-radius-x-small);
    outline: var(--drop-zone-drag-border-size) dashed black;
    display: unset;
    height: 100%;
    left: 0;
    margin: unset;
    min-height: var(--drop-zone-size);
    min-width: var(--drop-zone-size);
    top: 0;
    width: 100%;
  }

  .empty-layout > .drop-zone.dragging-over {
    outline: 3px solid var(--highlight-color);
  }

  .empty-layout > .drop-zone::before {
    content: unset;
  }
`;
