/* eslint-disable no-param-reassign */
import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import {
  BLOCK_STYLE_ELEMENT,
  DEFAULT_COLORS,
  DISCLAIMER_PRIVACY_TAG,
  DISCLAIMER_TERMS_TAG,
  INPUT_STYLE_ELEMENT,
  LABEL_STYLE_ELEMENT,
  LABEL_TEXT_ELEMENT,
  LINK_STYLE_ELEMENT,
  PHONE_REQUIRED_ERROR_MESSAGE,
  SMALL_STYLE_ELEMENT,
  STYLE_RULE_NAMES,
  SYSTEM_FONT_FAMILY_STYLE,
} from '../constants';
import renderEditorNode from '../development/editorNode';
import {
  AddBlockActionsProps,
  Block,
  BlockHelpers,
  BlockRenderPackage,
  Disclaimer,
  DisclaimerRenderData,
} from '../types';
import { HtmlSafeId } from '../types/render';
import { validateRequired } from '../utils/data';
import { renderError } from '../utils/render';
import { getFinalStyleRulesForBlock } from '../utils/styles';
import '../web-components/PhoneInputWrapper';

export type PhoneInputBlockTextElements = typeof PHONE_INPUT_TEXT_ELEMENTS;

const PHONE_INPUT_TEXT_ELEMENTS = {
  ...LABEL_TEXT_ELEMENT,
} as const;

const PHONE_INPUT_STYLE_ELEMENTS = {
  ...BLOCK_STYLE_ELEMENT,
  ...INPUT_STYLE_ELEMENT,
  ...LABEL_STYLE_ELEMENT,
  ...LINK_STYLE_ELEMENT,
  ...SMALL_STYLE_ELEMENT,
} as const;

const defaultStyles = {
  [PHONE_INPUT_STYLE_ELEMENTS.BLOCK]: {
    mobile: {
      [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
      [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.MARGIN]: '0px 0px 8px 0px',
      [STYLE_RULE_NAMES.OPACITY]: '1',
      [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
      // private
      [STYLE_RULE_NAMES.POSITION]: 'relative',
    },
  },
  [PHONE_INPUT_STYLE_ELEMENTS.LABEL]: {
    mobile: {
      [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
      [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
      [STYLE_RULE_NAMES.FONT_SIZE]: '14px',
      [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
      [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
      [STYLE_RULE_NAMES.MARGIN]: '0px 0px 4px 0px',
      [STYLE_RULE_NAMES.TEXT_ALIGN]: 'left',
      [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
      // private
      [STYLE_RULE_NAMES.DISPLAY]: 'block',
      [STYLE_RULE_NAMES.LINE_HEIGHT]: '1.2',
    },
  },
  [PHONE_INPUT_STYLE_ELEMENTS.INPUT]: {
    mobile: {
      [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.INPUT_BG,
      [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
      [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
      [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
      [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
      [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
      [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
      [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
      [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
      [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.TEXT_ALIGN]: 'left',
      [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
      // private
      [STYLE_RULE_NAMES.WIDTH]: '100%',
    },
  },
  [PHONE_INPUT_STYLE_ELEMENTS.SMALL]: {
    mobile: {
      [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
      [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
      [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
      [STYLE_RULE_NAMES.FONT_SIZE]: '10px',
      [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
      [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
      [STYLE_RULE_NAMES.MARGIN]: '8px 0px 0px 0px',
      [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
      // private
      [STYLE_RULE_NAMES.DISPLAY]: 'block',
      [STYLE_RULE_NAMES.LINE_HEIGHT]: '1.2',
    },
  },
  [PHONE_INPUT_STYLE_ELEMENTS.LINK]: {
    mobile: {
      [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
      // private
      [STYLE_RULE_NAMES.TEXT_DECORATION]: 'underline',
      [STYLE_RULE_NAMES.TEXT_TRANSFORM]: 'uppercase',
    },
  },
};

const addBlockActions = ({
  block,
  blockActions,
  popupActions,
  undeletableIds,
}: AddBlockActionsProps) => {
  blockActions.updateStepData = (event: InputEvent) => {
    const { value } = event.target as HTMLInputElement;
    const formattedValue = (
      (event.target as HTMLInputElement)?.closest(
        'ps-phone-input-block-iti-wrapper',
      ) as any
    )?.getFormattedInputValue();

    popupActions.updateStepData(block.id, formattedValue ?? value);
  };

  if (!undeletableIds?.includes(block.id))
    blockActions.deleteBlock = () => popupActions.deleteBlock?.(block.id);
};

const validateBlockData = (block: Block, data: string) => {
  const requiredErrorMessage = validateRequired<string>(
    block,
    data,
    PHONE_REQUIRED_ERROR_MESSAGE,
  );
  const lengthValidationErrorMessage = (
    document.querySelector('ps-phone-input-block-iti-wrapper') as any
  )?.getValidationError();

  return requiredErrorMessage ?? lengthValidationErrorMessage;
};

const getDisclaimerRenderData = (
  disclaimerObj: Disclaimer,
): DisclaimerRenderData => {
  const {
    privacy_policy: privacyPolicy,
    terms_of_service: termsOfService,
    text,
  } = disclaimerObj;

  return {
    disclaimerText: {
      preTerms: text.split(DISCLAIMER_TERMS_TAG)[0],
      terms: termsOfService.text,
      betweenTermsAndPrivacy: text
        .split(DISCLAIMER_TERMS_TAG)[1]
        .split(DISCLAIMER_PRIVACY_TAG)[0],
      privacy: privacyPolicy.text,
      postPrivacy: text.split(DISCLAIMER_PRIVACY_TAG)[1],
    },
    disclaimerUrls: {
      terms: termsOfService.url,
      privacy: privacyPolicy.url,
    },
  };
};

// This is mostly to satisfy TS
const fallbackItiStyles = {
  color: '',
  fontFamily: '',
  fontSize: '',
  margin: '',
};

function render(renderData: BlockRenderPackage) {
  const {
    block,
    blockActions,
    classes,
    content,
    disclaimer,
    environment,
    state,
    theme,
  } = renderData;

  const {
    block: blockClasses,
    editorNode: editorNodeClasses,
    input: inputClasses,
    label: labelClasses,
    link: linkClasses,
    small: smallClasses,
  } = classes;

  const labelContent = content[PHONE_INPUT_TEXT_ELEMENTS.LABEL] ?? 'Phone';

  const { disclaimerText, disclaimerUrls } =
    getDisclaimerRenderData(disclaimer);

  const finalInputStyleRules =
    getFinalStyleRulesForBlock(block, theme ?? {}, environment.viewport)[
      PHONE_INPUT_STYLE_ELEMENTS.INPUT
    ] ?? fallbackItiStyles;

  const inputId: HtmlSafeId = `input-${block.id}`;

  return html`
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(environment.isDevelopment ? 'true' : undefined)}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${environment.isDevelopment
        ? renderEditorNode(editorNodeClasses, blockActions.deleteBlock)
        : nothing}

      <label for=${inputId} class=${classMap(labelClasses)}>
        ${labelContent}
      </label>

      <ps-phone-input-block-iti-wrapper
        .countryCode="${environment.currentCountry}"
        .inputId="${inputId}"
        .finalInputStyleRules=${finalInputStyleRules}
      >
        <style>
          ps-phone-input-block-iti-wrapper {
            display: block;
          }

          ps-phone-input-block-iti-wrapper .iti {
            width: 100%;
          }

          ps-phone-input-block-iti-wrapper
            .iti--separate-dial-code
            .iti__selected-flag,
          ps-phone-input-block-iti-wrapper
            .iti--allow-dropdown
            .iti__flag-container:hover
            .iti__selected-flag {
            background-color: transparent;
          }

          ps-phone-input-block-iti-wrapper .iti__flag-container {
            color: ${finalInputStyleRules.color};
            font-family: ${finalInputStyleRules.fontFamily};
            font-size: ${finalInputStyleRules.fontSize};
          }

          ps-phone-input-block-iti-wrapper .iti__arrow {
            border-top-color: ${finalInputStyleRules.color};
          }

          .iti--container {
            font-family: ${finalInputStyleRules.fontFamily};
            font-size: ${finalInputStyleRules.fontSize};
          }
        </style>
        <input
          aria-describedby=${state?.error
            ? `${inputId}-error`
            : `${inputId}-disclaimer`}
          aria-invalid=${ifDefined(!!state?.error)}
          autocomplete=${environment.isDevelopment ? null : 'tel-national'}
          class=${classMap(inputClasses)}
          data-popup-engagement="true"
          id=${inputId}
          type="tel"
          @change=${ifDefined(blockActions.updateStepData)}
          required=${ifDefined(block.config?.required ? true : undefined)}
        />
      </ps-phone-input-block-iti-wrapper>

      ${renderError(state, inputId)}

      <small
        class=${classMap(smallClasses)}
        id="${inputId}-disclaimer"
        style="text-wrap: balance; text-wrap: pretty;"
      >
        ${disclaimerText.preTerms}
        <a
          aria-label=${`${disclaimerText.terms} (view in new window)`}
          class=${classMap(linkClasses)}
          data-popup-engagement="true"
          href=${ifDefined(disclaimerUrls.terms)}
          rel="noopener"
          target="_blank"
          >${disclaimerText.terms}</a
        >
        ${disclaimerText.betweenTermsAndPrivacy}
        <a
          aria-label=${`${disclaimerText.privacy} (view in new window)`}
          class=${classMap(linkClasses)}
          data-popup-engagement="true"
          href=${ifDefined(disclaimerUrls.privacy)}
          rel="noopener"
          target="_blank"
          >${disclaimerText.privacy}</a
        >${disclaimerText.postPrivacy}
      </small>
    </div>
  `;
}

const phoneInputBlockHelpers: BlockHelpers = {
  addBlockActions,
  defaultStyles,
  render,
  styleElements: PHONE_INPUT_STYLE_ELEMENTS,
  textElements: PHONE_INPUT_TEXT_ELEMENTS,
  validateBlockData,
};

export default phoneInputBlockHelpers;
