import { CustomFont } from '../../types';

export const loadCustomFontFamilies = async (
  fontFamiliesUsedInPopup: string[],
  customFonts: CustomFont[],
) => {
  const usedCustomFonts = customFonts.filter((c) =>
    fontFamiliesUsedInPopup.includes(c.name),
  );

  const styleTagContent = usedCustomFonts.map(
    (f) => `
        @font-face {
            font-family: '${f.name}';
            src: url('${f.src}');
        }
    `,
  );
  const styleTag = document.createElement('style');
  styleTag.innerText = styleTagContent.join('\n');
  document.head.appendChild(styleTag);
};
