/* eslint-disable no-param-reassign */
import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import {
  BLOCK_STYLE_ELEMENT,
  BUTTON_STYLE_ELEMENT,
  BUTTON_TEXT_ELEMENT,
  DEFAULT_COLORS,
  INPUT_STYLE_ELEMENT,
  OTP_LENGTH_ERROR_MESSAGE,
  PARAGRAPH_TEXT_ELEMENT,
  STYLE_RULE_NAMES,
  SYSTEM_FONT_FAMILY_STYLE,
  TEXT_STYLE_ELEMENT,
} from '../constants';
import renderEditorNode from '../development/editorNode';
import {
  AddBlockActionsProps,
  Block,
  BlockHelpers,
  BlockRenderPackage,
} from '../types';
import { getSanitizedOtpValue, validateStringLength } from '../utils/data';
import { renderError, renderResendOtpSuccessMessage } from '../utils/render';
import { HtmlSafeId } from '../types/render';

export type OtpInputBlockTextElements = typeof OTP_INPUT_TEXT_ELEMENTS;

const MAX_LENGTH = 4;

const OTP_INPUT_TEXT_ELEMENTS = {
  ...PARAGRAPH_TEXT_ELEMENT,
  ...BUTTON_TEXT_ELEMENT,
} as const;

const OTP_INPUT_STYLE_ELEMENTS = {
  ...BLOCK_STYLE_ELEMENT,
  ...BUTTON_STYLE_ELEMENT,
  ...INPUT_STYLE_ELEMENT,
  ...TEXT_STYLE_ELEMENT,
} as const;

const defaultStyles = {
  [OTP_INPUT_STYLE_ELEMENTS.BLOCK]: {
    mobile: {
      [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
      [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.MARGIN]: '0px 0px 8px 0px',
      [STYLE_RULE_NAMES.OPACITY]: '1',
      [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
      // private
      [STYLE_RULE_NAMES.POSITION]: 'relative',
    },
  },
  [OTP_INPUT_STYLE_ELEMENTS.BUTTON]: {
    mobile: {
      [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
      [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
      [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
      [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
      [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
      [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
      [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
      [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
      [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
      [STYLE_RULE_NAMES.MARGIN]: '8px 0px 8px 0px',
      [STYLE_RULE_NAMES.PADDING]: '8px 8px 8px 8px',
      [STYLE_RULE_NAMES.TEXT_DECORATION]: 'underline',
      // private
      [STYLE_RULE_NAMES.LINE_HEIGHT]: '1.2',
      [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
    },
  },
  [OTP_INPUT_STYLE_ELEMENTS.INPUT]: {
    mobile: {
      [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.INPUT_BG,
      [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
      [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
      [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
      [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
      [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
      [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
      [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
      [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
      [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
      [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
      // private
      [STYLE_RULE_NAMES.LETTER_SPACING]: '0.45em',
      [STYLE_RULE_NAMES.WIDTH]: '100%',
    },
  },
  [OTP_INPUT_STYLE_ELEMENTS.TEXT]: {
    mobile: {
      [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
      [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
      [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
      [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
      [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
      [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
      [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
      // private
      [STYLE_RULE_NAMES.LINE_HEIGHT]: '1.2',
      [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
    },
  },
};

const addBlockActions = ({
  block,
  blockActions,
  popupActions,
  undeletableIds,
}: AddBlockActionsProps) => {
  blockActions.updateStepData = (otp: string) => {
    popupActions.updateStepData(block.id, otp);
  };
  blockActions.getNode = popupActions.getNode;
  blockActions.resendOtp = () => popupActions.resendOtp?.(block);

  if (!undeletableIds?.includes(block.id))
    blockActions.deleteBlock = () => popupActions.deleteBlock?.(block.id);
};

const validateBlockData = (_: Block, data = '') => {
  const lengthError = validateStringLength(
    getSanitizedOtpValue(data),
    MAX_LENGTH,
    OTP_LENGTH_ERROR_MESSAGE,
  );

  return lengthError ?? null;
};

const DASHES = '––––';

function render(renderData: BlockRenderPackage) {
  const { block, blockActions, classes, content, environment, state } =
    renderData;
  const inputId: HtmlSafeId = `input-${block.id}`;

  const {
    block: blockClasses,
    button: buttonClasses,
    editorNode: editorNodeClasses,
    input: inputClasses,
    text: textClasses,
  } = classes;

  const paragraphContent = content[OTP_INPUT_TEXT_ELEMENTS.PARAGRAPH] ?? '';
  const buttonContent = content[OTP_INPUT_TEXT_ELEMENTS.BUTTON] ?? '';

  const handleOtpFocus = () => {
    const input = blockActions.getNode(
      `#${inputId}`,
    ) as HTMLInputElement | null;

    if (input) {
      const currentValue = input.value;
      const numbers = currentValue
        .split('')
        .filter((char) => !Number.isNaN(parseInt(char, 10)));
      // Set timeout because otherwise the input isn't actually fully focused yet, so
      // setSelectionRange does nothing.
      setTimeout(
        () => input.setSelectionRange(numbers.length, numbers.length),
        0,
      );
    }
  };

  const handleOtpChange = (event: InputEvent) => {
    const { value } = event.target as HTMLInputElement;
    const input = blockActions.getNode(
      `#${inputId}`,
    ) as HTMLInputElement | null;
    if (!input || !value) return;

    const valueAsArr = value
      .split('')
      .filter((char) => !Number.isNaN(parseInt(char, 10)));
    const hasRequiredCharacters = valueAsArr.length === MAX_LENGTH;

    let formattedValue = '';

    if (hasRequiredCharacters) {
      const valueAsString = valueAsArr.slice(0, MAX_LENGTH).join('');
      formattedValue = valueAsString;
      input.value = valueAsString;
      input.blur();
    } else {
      const dashesToAdd = Math.max(MAX_LENGTH - valueAsArr.length, 0);
      formattedValue = `${valueAsArr.join('')}${'-'.repeat(dashesToAdd)}`;
    }

    // Update state, input UI, and focus cursor
    blockActions.updateStepData(formattedValue);
    input.value = formattedValue;
    input.setSelectionRange(valueAsArr.length, valueAsArr.length);
  };

  return html`
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(environment.isDevelopment ? 'true' : undefined)}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${environment.isDevelopment
        ? renderEditorNode(editorNodeClasses, blockActions.deleteBlock)
        : nothing}

      <p class=${classMap(textClasses)} id="${inputId}-onsite-resend-wrapper">
        <span style="display: block;">${paragraphContent}</span>
        <span style="display: block;">
          <button
            class=${classMap(buttonClasses)}
            data-popup-engagement="true"
            id="${inputId}-resend-code-button"
            type="button"
            @click=${ifDefined(
              !environment.isDevelopment && blockActions.resendOtp
                ? blockActions.resendOtp
                : undefined,
            )}
          >
            ${buttonContent}
          </button>
          ${renderResendOtpSuccessMessage(state, inputId)}
        </span>
      </p>

      <input
        aria-describedby=${ifDefined(
          state?.error
            ? `${inputId}-error`
            : `${inputId}-onsite-resend-wrapper`,
        )}
        aria-invalid=${ifDefined(!!state?.error)}
        aria-label="We sent you a four digit one-time code to enter below"
        autocomplete="one-time-code"
        class=${classMap(inputClasses)}
        data-popup-engagement="true"
        id=${inputId}
        inputmode="numeric"
        name=${inputId}
        pattern=${`[0-9]{4}`}
        placeholder=${DASHES}
        type="text"
        value=${DASHES}
        @focus=${handleOtpFocus}
        @input=${handleOtpChange}
        required=${ifDefined(block.config?.required ? true : undefined)}
      />
      ${renderError(state, inputId)}
    </div>
  `;
}

const otpInputBlockHelpers: BlockHelpers = {
  addBlockActions,
  defaultStyles,
  render,
  styleElements: OTP_INPUT_STYLE_ELEMENTS,
  textElements: OTP_INPUT_TEXT_ELEMENTS,
  validateBlockData,
};

export default otpInputBlockHelpers;
