import WebFont from 'webfontloader';
import { BlockPopup, ScreenSize } from '../../third-party-dep/types';
import { getUniqueGoogleFontFamiliesInPopup } from '../../third-party-dep/utils/styles';

export const loadGoogleFontsForPopup = (
  { stepBlocks, teaserBlocks, theme }: BlockPopup,
  viewport: ScreenSize,
) => {
  const googleFontFamilies = getUniqueGoogleFontFamiliesInPopup({
    stepBlocks,
    teaserBlocks,
    theme,
    viewport,
  });

  if (googleFontFamilies.length) {
    WebFont.load({
      classes: false,
      events: false,
      google: {
        families: googleFontFamilies,
      },
    });
  }
};
