/* eslint-disable no-param-reassign */
import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import {
  BUTTON_STYLE_ELEMENT,
  BUTTON_TEXT_ELEMENT,
  DEFAULT_COLORS,
  STYLE_RULE_NAMES,
  SYSTEM_FONT_FAMILY_STYLE,
} from '../constants';
import renderEditorNode from '../development/editorNode';
import {
  AddBlockActionsProps,
  BlockHelpers,
  BlockRenderPackage,
} from '../types';

export type TeaserBlockTextElements = typeof TEASER_BLOCK_TEXT_ELEMENTS;

const TEASER_BLOCK_TEXT_ELEMENTS = {
  ...BUTTON_TEXT_ELEMENT,
} as const;

const TEASER_BLOCK_STYLE_ELEMENTS = {
  ...BUTTON_STYLE_ELEMENT,
} as const;

const defaultStyles = {
  [TEASER_BLOCK_STYLE_ELEMENTS.BUTTON]: {
    mobile: {
      [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.PRIMARY_BUTTON_BG,
      [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
      [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
      [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
      [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.PRIMARY_BUTTON_TEXT,
      [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
      [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
      [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
      [STYLE_RULE_NAMES.FONT_WEIGHT]: '400',
      [STYLE_RULE_NAMES.PADDING]: '8px 8px 8px 8px',
      [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
      // private
      [STYLE_RULE_NAMES.WIDTH]: '100%',
    },
  },
};

const addBlockActions = ({
  block,
  blockActions,
  environment,
  popupActions,
  undeletableIds,
}: AddBlockActionsProps) => {
  blockActions.onClick = () => {
    if (environment?.isDevelopment) {
      popupActions.selectBlock?.(block.id);
    } else {
      popupActions.handleTeaserClick();
    }
  };

  if (!undeletableIds?.includes(block.id))
    blockActions.deleteBlock = () => popupActions.deleteBlock?.(block.id);
};

function render(renderData: BlockRenderPackage) {
  const { blockActions, classes, content, environment } = renderData;

  const { button: buttonClasses, editorNode: editorNodeClasses } = classes;

  const buttonContent = content[TEASER_BLOCK_TEXT_ELEMENTS.BUTTON] ?? 'Button';

  return html`
    <div @click=${ifDefined(blockActions.onClick)}>
      ${environment.isDevelopment
        ? renderEditorNode(editorNodeClasses, blockActions.deleteBlock)
        : nothing}
      <button class=${classMap(buttonClasses)}>${buttonContent}</button>
    </div>
  `;
}

const teaserBlockHelpers: BlockHelpers = {
  addBlockActions,
  defaultStyles,
  render,
  styleElements: TEASER_BLOCK_STYLE_ELEMENTS,
  textElements: TEASER_BLOCK_TEXT_ELEMENTS,
};

export default teaserBlockHelpers;
