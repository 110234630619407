import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

const updatePhoneInput = (
  countryCode: string | null,
  selector = '#ps-desktop-widget__phone-input',
  config: Partial<intlTelInput.Options> = {},
) => {
  const input = document.querySelector(selector);

  if (!input) return null;

  return intlTelInput(input, {
    separateDialCode: true,
    preferredCountries: ['us', 'ca'],
    initialCountry: countryCode ?? 'US',
    utilsScript:
      'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js',
    ...config,
  });
};

export default updatePhoneInput;
