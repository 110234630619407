/* eslint-disable no-param-reassign */
import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import {
  BLOCK_STYLE_ELEMENT,
  DEFAULT_COLORS,
  MAIN_POPUP_CONTAINER_STYLE_ELEMENT,
  ROOT_BACKGROUND_IMAGE_DIV,
  ROOT_GRID_AREAS,
  ROOT_GRID_COLUMNS,
  ROOT_LAYOUTS,
  STYLE_ELEMENT_TYPES,
  STYLE_RULE_NAMES,
} from '../constants';
import renderEditorNode from '../development/editorNode';
import {
  AddBlockActionsProps,
  Block,
  BlockHelpers,
  BlockRenderPackage,
} from '../types';
import { getFinalStyleRulesForBlock } from '../utils/styles';

const ROOT_BLOCK_STYLE_ELEMENTS = {
  ...ROOT_BACKGROUND_IMAGE_DIV,
  ...BLOCK_STYLE_ELEMENT,
  ...MAIN_POPUP_CONTAINER_STYLE_ELEMENT,
};

const defaultStyles = {
  [ROOT_BLOCK_STYLE_ELEMENTS.BLOCK]: {
    mobile: {
      [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
      [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.POPUP_BG,
      // static
      [STYLE_RULE_NAMES.DISPLAY]: 'grid',
      [STYLE_RULE_NAMES.GRID_TEMPLATE_COLUMNS]: `repeat(${ROOT_GRID_COLUMNS}, 1fr)`,
      [STYLE_RULE_NAMES.OVERFLOW]: 'hidden',
      [STYLE_RULE_NAMES.POSITION]: 'relative',
      [STYLE_RULE_NAMES.ROOT_LAYOUT]: ROOT_LAYOUTS.NONE,
      [STYLE_RULE_NAMES.MAX_WIDTH]: '100%',
      [STYLE_RULE_NAMES.MIN_HEIGHT]: '100%',
      [STYLE_RULE_NAMES.WIDTH]: '100%',
    },
  },
  [ROOT_BLOCK_STYLE_ELEMENTS.MAIN_POPUP_CONTAINER]: {
    mobile: {
      [STYLE_RULE_NAMES.LAYOUT_DIRECTION]: 'column',
      [STYLE_RULE_NAMES.LAYOUT_SPACING]: 'center',
      [STYLE_RULE_NAMES.OPACITY]: '1',
      [STYLE_RULE_NAMES.PADDING]: '30px 30px 30px 30px',
      // static
      [STYLE_RULE_NAMES.DISPLAY]: 'flex',
      [STYLE_RULE_NAMES.GRID_AREA]: ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER,
      [STYLE_RULE_NAMES.WIDTH]: '100%',
    },
  },
  [ROOT_BLOCK_STYLE_ELEMENTS.ROOT_BACKGROUND_IMAGE_DIV]: {
    mobile: {
      [STYLE_RULE_NAMES.HEIGHT]: '200px',
      // static
      [STYLE_RULE_NAMES.BACKGROUND_POSITION]: '50% 50%',
      [STYLE_RULE_NAMES.BACKGROUND_SIZE]: 'cover',
      [STYLE_RULE_NAMES.DISPLAY]: 'block',
      [STYLE_RULE_NAMES.GRID_AREA]: ROOT_GRID_AREAS.DECORATIVE,
      [STYLE_RULE_NAMES.WIDTH]: '100%',
    },
  },
};

const addBlockActions = ({
  block,
  blockActions,
  popupActions,
  undeletableIds,
}: AddBlockActionsProps) => {
  if (!undeletableIds?.includes(block.id))
    blockActions.deleteBlock = () => popupActions.deleteBlock?.(block.id);
};

function isEmpty({ children = [] }: Block) {
  return children.length === 0;
}

function render(renderData: BlockRenderPackage) {
  const { block, blockActions, classes, environment, theme } = renderData;
  const finalStyles = getFinalStyleRulesForBlock(
    block,
    theme,
    environment.viewport,
  );
  const backgroundImage =
    finalStyles[STYLE_ELEMENT_TYPES.ROOT_BACKGROUND_IMAGE_DIV]?.[
      STYLE_RULE_NAMES.BACKGROUND_IMAGE
    ];

  const {
    block: blockClasses,
    rootBackgroundImageDiv: rootBackgroundImageDivClasses,
    editorNode: editorNodeClasses,
    mainPopupContainer: mainPopupContainerClasses,
  } = classes;

  return html`
    <div class=${classMap(blockClasses)}>
      ${backgroundImage
        ? html`<div class=${classMap(rootBackgroundImageDivClasses)}></div>`
        : nothing}
      <form
        class=${classMap(mainPopupContainerClasses)}
        novalidate
        @click=${ifDefined(blockActions.selectBlock)}
        @submit=${(event: SubmitEvent) => event.preventDefault()}
      >
        ${environment.isDevelopment
          ? renderEditorNode(editorNodeClasses, blockActions.deleteBlock)
          : nothing}
        ${renderData.children}
      </form>
    </div>
  `;
}

const rootTempBlockHelpers: BlockHelpers = {
  addBlockActions,
  defaultStyles,
  isEmpty,
  render,
  styleElements: ROOT_BLOCK_STYLE_ELEMENTS,
};

export default rootTempBlockHelpers;
