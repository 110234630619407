import {
  POPUP_EVENT_TYPES,
  SHOPIFY_DISCOUNT_CODE_COOKIE_NAME,
} from '../../helpers/constants';

import {
  acceptMobileEvent,
  appendServerIdToOptInMessage,
  collectedEmailEvent,
  getTwoTapSessionId,
  sendAttributesEvent,
  configureSubscriptionEnvironment,
  setTwoTapCookie,
  trackPopupEvent,
} from '../../helpers/events';
import {
  FormSubmitCommand,
  HandleOptInEndpoint,
  HandleOtpValidation,
  IsPreventingNextScreen,
  TransitionToSubscriber,
} from '../../helpers/types';
import { attemptAutoApplyFondueCashback } from '../helpers/fondue-helpers';
import { optIn, validateOTP } from '../../sdk/core/onsite-opt-in/service';
import {
  isKeywordMessageOptIn,
  isTwoTouchPopup,
} from '../helpers/optInMethods';
import {
  ONE_TIME_PASSCODE_INPUT_NAME,
  QUESTION_OPT_IN_METHODS,
  QUESTION_TYPES,
} from './constants';
import { setCookieOnParentDocument } from '../../helpers/iframe';
import { submitFingerprintData } from '../../helpers/fingerprint';
import { getOtpVerifyErrors } from '../otpUtils';

export const handleCustomAttributes = ({
  formData,
  hostComponent,
}: FormSubmitCommand) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customAttributes: Record<string, any> = {};
  formData.forEach((value: FormDataEntryValue, key: string) => {
    if (key !== 'phone' && key !== ONE_TIME_PASSCODE_INPUT_NAME) {
      customAttributes[key] = value;
    }
  });

  // Prevent submitting an empty payload if no custom attributes are in the form
  if (Object.keys(customAttributes).length === 0) {
    return;
  }

  sendAttributesEvent({
    popup_id: hostComponent.popupTemplateProps.id,
    shop_id: hostComponent.popupTemplateProps.shopId,
    source: hostComponent.source,
    session_id: hostComponent.popupTemplateProps.sessionId,
    subscriber_id: window.ps__subscriber_id,
    token: window.ps__token,
    server_id: window.ps__server_id,
    ...customAttributes,
  });
};

export const trackEmailConversion = ({
  formData,
  hostComponent,
}: FormSubmitCommand) => {
  const email = formData.get('email') as string;
  if (!email) {
    return;
  }

  window.ps__email = email;
  collectedEmailEvent(email);
  trackPopupEvent(
    POPUP_EVENT_TYPES.SUBMIT_EMAIL,
    hostComponent.popupTemplateProps.shopId,
    hostComponent.popupTemplateProps.id as number,
    hostComponent.popupTemplateProps.currentCountry,
  );
};

export const handleKeywordMessageOptIn = async ({
  hostComponent,
}: FormSubmitCommand) => {
  const hasKeywordMessageOptInQuestion =
    isKeywordMessageOptIn(hostComponent.popupTemplateProps) &&
    hostComponent.page.questions.some((q) => q.type === QUESTION_TYPES.PHONE);

  if (!hasKeywordMessageOptInQuestion) {
    return;
  }

  const twoTapSessionId = await getTwoTapSessionId(
    hostComponent.popupTemplateProps.id as number,
    hostComponent.popupTemplateProps.sessionId,
  );
  const twoTapSessionMessage = appendServerIdToOptInMessage(
    hostComponent.popupTemplateProps.smsMessage as string,
    twoTapSessionId,
    isTwoTouchPopup(hostComponent.popupTemplateProps) ? 'sid' : 'pid',
  );
  setTwoTapCookie(twoTapSessionId);
  acceptMobileEvent(
    hostComponent.popupTemplateProps.currentCountry,
    hostComponent.popupTemplateProps.countryPhoneNumber as string,
    twoTapSessionMessage,
  );
};

const transitionToSubscriber = async ({
  autoApplyOfferEnabled,
  shopId,
  responseData: {
    success,
    cashback_utm_code: cashbackUtmCode,
    coupon_code: couponCode,
    subscriber_id: subscriberId,
  },
}: TransitionToSubscriber) => {
  if (!subscriberId || !success) return;

  // After successful opt-in, set subscriber cookie
  configureSubscriptionEnvironment({ subscriberId: +subscriberId });
  // Fingerprint user
  submitFingerprintData(true, shopId?.toString(), subscriberId.toString());

  if (autoApplyOfferEnabled) {
    if (cashbackUtmCode) {
      await attemptAutoApplyFondueCashback(cashbackUtmCode);
    }

    if (couponCode) {
      setCookieOnParentDocument(
        SHOPIFY_DISCOUNT_CODE_COOKIE_NAME,
        couponCode,
        undefined,
        undefined,
        true,
      );
    }
  }
};

export const handleOptInEndpoint = async ({
  submitCommand: { event, formData, hostComponent, optInMethod },
  showExistingOtpSubscriberStaticPageCallback,
  showOneTimePasscodeInputCallback,
  updatePhoneNumberStateValueCallback,
}: HandleOptInEndpoint): Promise<IsPreventingNextScreen> => {
  const phoneValue = formData.get('phone') as string;
  if (!phoneValue) return false;

  const form = event.target as HTMLFormElement;
  const input = form.querySelector('ps-phone-input');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const countryData = (input as any).getFormattedInputValue();
  updatePhoneNumberStateValueCallback(countryData.phone);

  /* Intentionally not awaiting on this line because double opt-in and OTP use
  this endpoint, but double opt-in does not make use of submit controller state
  so nextScreen can be called immediately after request. */
  const optInRequest = optIn({
    countryCode: countryData.country,
    id: hostComponent.popupTemplateProps.id as number,
    phoneNumber: countryData.phone,
    sessionId: hostComponent.popupTemplateProps.sessionId,
    shopId: hostComponent.popupTemplateProps.shopId,
    source: hostComponent.source,
    optInMethod,
  });

  const shouldCallNextScreenImmediately =
    optInMethod === QUESTION_OPT_IN_METHODS.DOUBLE_OPT_IN ||
    optInMethod === null ||
    optInMethod === undefined;

  if (shouldCallNextScreenImmediately) {
    return false;
  }

  // OTP synchronously awaits response
  const { subscriberId } = await optInRequest;
  const isExistingSubscriber = !!subscriberId;

  if (isExistingSubscriber) {
    showExistingOtpSubscriberStaticPageCallback();

    return true;
  }

  showOneTimePasscodeInputCallback();
  return true;
};

export const handleOtpValidation = async ({
  submitCommand,
  autoApplyOfferEnabled,
  updateErrorsCallback,
  showNewSubscriberStaticPageMessage,
}: HandleOtpValidation): Promise<IsPreventingNextScreen> => {
  const { formData, hostComponent, phoneNumber } = submitCommand;
  const formOneTimePasscodeValue = formData.get(ONE_TIME_PASSCODE_INPUT_NAME);
  const isSubmittingOTPInput = !!formOneTimePasscodeValue;

  if (!isSubmittingOTPInput) {
    return false;
  }

  /* Clear errors before submission so that if user submits consecutive invalid
  codes the errors have reset before being changed to true again upon
  validating. Without this, clearing the input only works once. */
  updateErrorsCallback({ general: false, validation: false });

  if (!phoneNumber) {
    updateErrorsCallback({ general: true });
    return true;
  }

  const response = await validateOTP(
    hostComponent.popupTemplateProps.shopId,
    phoneNumber,
    formOneTimePasscodeValue as string,
  );

  const { general, verification } = getOtpVerifyErrors(response);

  if (general) {
    updateErrorsCallback({ general });
    return true;
  }

  if (verification) {
    updateErrorsCallback({ validation: verification });
    return true;
  }

  // Validated
  if (!response?.data) return true;

  await transitionToSubscriber({
    autoApplyOfferEnabled,
    shopId: hostComponent.popupTemplateProps.shopId,
    responseData: response.data,
  });

  showNewSubscriberStaticPageMessage();

  return true;
};
