import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { RenderContext } from '../types';

const DRAGGING_OVER = 'dragging-over';

const renderDropZone = (
  renderContext: RenderContext,
  blockId: string,
  dropIndex: number,
) => {
  const {
    popupActions: { handleAddDrop, handleReorderDrop },
    environment: { isDraggingAddBlock, isDraggingReorderBlock },
  } = renderContext;

  // The drop event won't work without preventing the default for this event too
  const handleDragOver = (event: DragEvent) => event.preventDefault();

  const handleDragEnter = (event: DragEvent) => {
    (event.currentTarget as HTMLElement).classList.add(DRAGGING_OVER);
  };

  const handleDragLeave = (event: DragEvent) => {
    (event.currentTarget as HTMLElement).classList.remove(DRAGGING_OVER);
  };

  const handleDrop = (event: DragEvent) => {
    (event.currentTarget as HTMLElement).classList.remove(DRAGGING_OVER);
    if (isDraggingAddBlock) handleAddDrop?.(blockId, dropIndex);
    if (isDraggingReorderBlock) handleReorderDrop?.(blockId, dropIndex);
  };

  return html`
    <div
      @dragover=${handleDragOver}
      @dragenter=${handleDragEnter}
      @dragleave=${handleDragLeave}
      @drop=${handleDrop}
      class=${classMap({
        dragging: !!(isDraggingAddBlock || isDraggingReorderBlock),
        'drop-zone': true,
      })}
    ></div>
  `;
};

export default renderDropZone;
