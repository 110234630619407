import { html, TemplateResult } from 'lit';
import { FADE_IN_CLASS, handlePopupKeyDown } from '../../../../helpers/ui';
import { BLOCK_POPUP_CONTAINER_ID } from '../constants';

const modalPopupContainer = (
  content: TemplateResult<1>,
  onExit: unknown,
) => html`
  <div
    id="${BLOCK_POPUP_CONTAINER_ID}"
    class="${FADE_IN_CLASS}"
    aria-modal="true"
    role="dialog"
    @keydown=${handlePopupKeyDown(onExit)}
  >
    <div id="ps-block-popup__background">
      <div id="ps-block-popup__click-background" @click=${onExit}></div>
      <div id="ps-block-popup__body-container">${content}</div>
    </div>
  </div>
`;

export default modalPopupContainer;
